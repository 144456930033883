import clsx from "clsx";

import Scrollbar from "components/General/Scrollbar";
import AudioSamplePlayer from "components/General/AudioSamplePlayer";

import ContextHeader from "../../Reusables/ContextHeader";
import ContextSearchInput from "../../Reusables/SearchInput";
import SectionTitle from "../../Reusables/SectionTitle";

import styles from "./style.module.scss";
import { useEffect } from "react";
import { useVideoEditor } from "contexts/VideoEditorContext";
import { voices } from "support/voices";

const cdnHost = process.env.REACT_APP_CDN_HOST;


export default function VoiceoverContext() {
  const { video, setVideo } = useVideoEditor();

  useEffect(() => {
    if (!video) {
      return;
    }

    if (!video.voiceoverVoice) {
      setVideo({ ...video, voiceoverVoice: voices[0].name });
    }
  }, [video, setVideo]);

  const handleVoiceChange = (name: string) => {
    if (!video) {
      return;
    }

    setVideo({ ...video, voiceoverVoice: name });
  };

  return (
    <div className={styles.navTextContext}>
      <ContextHeader>
        <ContextSearchInput placeholder="Search Voices..." />
      </ContextHeader>

      <Scrollbar className={styles.voiceoverContextBody}>
        <SectionTitle>AI Voiceovers</SectionTitle>
        <div className={styles.voicesWrap}>
          {voices.map(voice => (
            <div
              key={voice.id}
              className={clsx(styles.voiceItemWrap, {
                [styles.active]: video?.voiceoverVoice === voice.id
              })}
              onClick={() => handleVoiceChange(voice.id)}
            >
              <div key={voice.id} className={styles.voiceItem}>
                 <div className={styles.voiceName}>{voice.name}</div>

                <AudioSamplePlayer
                  className="voice-sammple-player"
                  src={`${cdnHost}/assets/voices/${voice.sample}`}
                  playButtonLabel="Sample"
                />
              </div>
            </div>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
}
