import { ChannelPlatform } from "./types";

const channelPlatforms: ChannelPlatform[] = [
  {
    id: "facebook",
    name: "Facebook",
    icon: "facebook-colored"
  },
  {
    id: "instagram",
    name: "Instagram",
    icon: "instagram-colored"
  },
  {
    id: "tiktok",
    name: "Tiktok",
    icon: "tiktok-colored"
  },
  {
    id: "youtube",
    name: "Youtube",
    icon: "youtube-colored"
  }
];

export default channelPlatforms;
