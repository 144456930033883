import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useCallback
} from "react";

import { VideoPlayerModal } from "components/Editor/VideoPlayerModal";

import toast from "support/toast";
import useBackend from "hooks/useBackend";
import SocketClient from "support/SocketClient";
import alertController from "./AlertContext/controller";
import { Video, VideoScene } from "support/types/videos";
import useTooltip from "hooks/useTooltip";

interface VideoEditorContextProps {
  isSideNavCollapsed: boolean;
  setIsSideNavCollapsed: (collapse: boolean) => void;
  isVideoLoading: boolean;
  isVideoBusy: boolean;
  video?: Video;
  setVideo: (video: Video) => void;
  isScenesLoading: boolean;
  scenes?: VideoScene[];
  setScenes: (scenes: VideoScene[]) => void;
  someScenesPending: boolean;
  currentScene?: VideoScene;
  hasChanges: boolean;
  currentWord: string;
  setCurrentWord: (word: string) => void;
  setCurrentSceneIndex: (i: number) => void;
  updateScene: (scene: VideoScene) => void;
  regenerateSceneImage: () => void;
  isVideoPlayable: boolean;
  playVideo: () => void;
}

type ObjectWithPath = { [key: string]: any };

// Function to get the value from the object based on the key path
function getObjectValue(obj: ObjectWithPath, keyPath: string): any {
  return keyPath.split(".").reduce((acc, key) => acc && acc[key], obj);
}

function transformToMergedObject(
  obj: ObjectWithPath,
  keys: string[]
): ObjectWithPath {
  const result: ObjectWithPath = {};

  keys.forEach(key => {
    const parts = key.split("."); // Split the path by dot notation
    let current = result;

    // Traverse and build the object structure
    parts.forEach((part, index) => {
      if (index === parts.length - 1) {
        // Final part, assign the value
        current[part] = getObjectValue(obj, key);
      } else {
        // Create a nested object if it doesn't exist
        current[part] = current[part] || {};
      }
      current = current[part];
    });
  });

  return result;
}

const VideoEditorContext = createContext<VideoEditorContextProps | undefined>(
  undefined
);

export const useVideoEditor = (): VideoEditorContextProps => {
  const context = useContext(VideoEditorContext);
  if (!context) {
    throw new Error("useVideoEditor must be used within a VideoEditorProvider");
  }
  return context;
};

interface VideoEditorProviderProps {
  videoId: string;
  children: ReactNode;
}

export const VideoEditorProvider: FC<VideoEditorProviderProps> = ({
  videoId,
  children
}) => {
  const [isSideNavCollapsed, setIsSideNavCollapsed] = useState(false);
  const [video, setVideo] = useState<Video>();
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isScenesLoading, setIsScenesLoading] = useState(true);
  const [scenes, setScenes] = useState<VideoScene[]>([]);
  const [currentSceneIndex, setCurrentSceneIndex] = useState<number>(0);
  const [currentWord, setCurrentWord] = useState<string>("");
  const [watchVideoFields, setWatchVideoFields] = useState<string[]>([]);
  const [watchSceneFields, setWatchSceneFields] = useState<string[]>([]);
  const [isShowVideoPlayerModal, setIsShowVideoPlayerModal] = useState(false);

  const { get, post } = useBackend();

  useTooltip();

  const currentScene = scenes.length ? scenes[currentSceneIndex] : undefined;

  const updateScene = useCallback(
    (scene: VideoScene) => {
      const sceneIndex = scenes.findIndex(s => s.id === scene.id);
      if (sceneIndex !== -1) {
        const updatedScenes = [
          ...scenes?.slice(0, sceneIndex),
          scene,
          ...scenes?.slice(sceneIndex + 1)
        ];

        setScenes(updatedScenes);
      }
    },
    [scenes]
  );

  useEffect(() => {
    if (!videoId) {
      return;
    }
    const controller = new AbortController();

    get(`/videos/${videoId}`, { signal: controller.signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setVideo(jsonResonponse.data.video);

            setWatchVideoFields(jsonResonponse.data.watchVideoFields);
            setWatchSceneFields(jsonResonponse.data.watchSceneFields);
          } catch (error) {}
        }
        setIsVideoLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsVideoLoading(false);
        }
      });
  }, [get, videoId]);

  useEffect(() => {
    if (!videoId) {
      return;
    }
    const controller = new AbortController();

    if (video?.scenesReady) {
      get(`/videos/${videoId}/scenes`, { signal: controller.signal })
        .then(async res => {
          if (res.ok) {
            try {
              const jsonResonponse = await res.json();

              const storedScenes = jsonResonponse.data.scenes;

              setScenes(storedScenes);

              const sceneIndex = 0;
              setCurrentSceneIndex(sceneIndex);

              const scene = storedScenes[sceneIndex];
              const words = scene.script?.trim().split(/\s+/);

              setCurrentWord(words?.length ? words[0] : "");
            } catch (error) {}
          }
          setIsScenesLoading(false);
        })
        .catch(e => {
          if (e.name !== "AbortError") {
            setIsScenesLoading(false);
          }
        });
    }

    return () => {
      // controller.abort();
    };
  }, [get, videoId, video?.scenesReady]);

  useEffect(() => {
    const socketClient = SocketClient.getInstance();
    const socket = SocketClient.getSocket();

    const handleRenderStarted = ({ video }: { video: Video }) => {
      setVideo(video);
    };

    const handleVideoUpdated = ({ video }: { video: Video }) => {
      setVideo(video);
    };

    const handleRenderFailed = ({
      video,
      failedReason
    }: {
      video: Video;
      failedReason: string;
    }) => {
      handleVideoUpdated({ video });

      toast.error(failedReason);
    };

    const handleSceneUpdated = ({ scene }: { scene: VideoScene }) => {
      updateScene(scene);
    };

    const handleRenderComplete = ({ video }: { video: Video }) => {
      alertController.open({
        icon: "success",
        message: "Your video has been rendered successfully!",
        buttonText: "Play Video",
        cancelButtonText: "Close",
        onOkay: playVideo
      });

      handleVideoUpdated({ video });
    };

    const handleRenderError = ({ message }: { message: string }) => {
      toast.error(message);
    };

    const videoChannel = `video-${videoId}`;
    socketClient.subscribeToChannel(videoChannel);

    socket.on("renderStarted", handleRenderStarted);

    socket.on("videoUpdated", handleVideoUpdated);

    socket.on("renderComplete", handleRenderComplete);

    socket.on("renderFailed", handleRenderFailed);

    socket.on("sceneUpdated", handleSceneUpdated);
    socket.on("renderError", handleRenderError);

    return () => {
      socket.off("renderStarted", handleRenderStarted);
      socket.off("videoUpdated", handleVideoUpdated);
      socket.off("renderComplete", handleRenderComplete);
      socket.off("renderFailed", handleRenderFailed);
      socket.on("sceneUpdated", handleSceneUpdated);
      socket.on("renderError", handleRenderError);

      socketClient.unsubscribeFromChannel(videoChannel);
    };
  }, [videoId, updateScene]);

  const regenerateSceneImage = async () => {
    if (!video || !currentScene) {
      return;
    }

    const pendingScene = JSON.parse(JSON.stringify(currentScene)); // Create a deep object copy
    pendingScene.status = "queueing";
    delete pendingScene.image.thumbUrl;

    updateScene(pendingScene);

    try {
      const res = await post(`/videos/${video.id}/regenerate-scene`, {
        body: {
          sceneId: currentScene.id,
          prompt: currentScene.image?.prompt
        }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        toast.error(jsonResonponse.message);

        // Reset scene
        updateScene(currentScene);
      } else {
        const updatedScene = { ...jsonResonponse.data.scene };

        if (updatedScene.status === "queueing" && updatedScene.image) {
          delete updatedScene.image.thumbUrl;
        }

        updateScene(updatedScene);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const getVideoSignature = (video: Video, scenes: VideoScene[]) => {
    const watchVideoFieldValues = transformToMergedObject(
      video,
      watchVideoFields
    );
    const watchSceneFieldValues = scenes.map(scene => {
      return transformToMergedObject(scene, watchSceneFields);
    });

    return JSON.stringify({
      video: watchVideoFieldValues,
      scenes: watchSceneFieldValues
    });
  };

  const playVideo = () => {
    setIsShowVideoPlayerModal(true);
  };

  const handleOnCloseVideoPlayer = () => {
    setIsShowVideoPlayerModal(false);
  };

  const someScenesPending = scenes.some(s => s.status !== "ready");

  const hasChanges =
    !!video &&
    video.prevRender !== getVideoSignature(video, scenes) &&
    !someScenesPending;

  const isVideoPlayable =
    !!video && video.status === "ready" && !hasChanges && !someScenesPending;

  const isVideoBusy =
    someScenesPending ||
    video?.status === "queueing" ||
    video?.status === "generating" ||
    video?.status === "rendering";

  return (
    <VideoEditorContext.Provider
      value={{
        isSideNavCollapsed,
        setIsSideNavCollapsed,
        hasChanges,
        video,
        setVideo,
        isVideoLoading,
        isVideoBusy,
        isScenesLoading,
        scenes,
        setScenes,
        someScenesPending,
        currentScene,
        setCurrentSceneIndex,
        updateScene,
        currentWord,
        setCurrentWord,
        isVideoPlayable,
        playVideo,
        regenerateSceneImage
      }}
    >
      {children}

      {video && isShowVideoPlayerModal && (
        <VideoPlayerModal
          video={video}
          onClose={handleOnCloseVideoPlayer}
        ></VideoPlayerModal>
      )}
    </VideoEditorContext.Provider>
  );
};
