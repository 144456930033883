import clsx from "clsx";
import { ChangeEvent, useState } from "react";

import SvgIcon from "components/General/SvgIcon";

import toast from "support/toast";

import useBackend from "hooks/useBackend";

import SelectVideoSizeDropdown from "components/General/SelectVideoSizeDropdown";
import Spinner from "components/General/Spinner";
// import SelectVoiceDropdown from "components/General/SelectVoiceDropdown";
import { Link } from "react-router-dom";
import { useVideoEditor } from "contexts/VideoEditorContext";
import { AvailableCredits } from "components/Dashboard/AvailableCredits";

import styles from "./style.module.scss";
import ShareVideoDropdown from "components/General/ShareVideoDropdown";

const titleMaxLength = 100;

export default function EditorTopNav() {
  const [size, setSize] = useState("1080x1920");
  const [isSendingForRerender, setIsSendingForRerender] = useState(false);

  const {
    scenes,
    isVideoLoading,
    isVideoBusy,
    video,
    setVideo,
    setScenes,
    hasChanges,
    isVideoPlayable,
    playVideo
  } = useVideoEditor();

  const { put } = useBackend();

  const handleVideoSizeChange = (size: string) => {
    size && setSize(size);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!video) {
      return;
    }

    setVideo({ ...video, title: e.target.value });
  };

  const handleRerender = async () => {
    if (!video) {
      return;
    }

    setIsSendingForRerender(true);

    setVideo({ ...video, status: "queueing" });

    try {
      const res = await put(`/videos/magicgen/${video.id}`, {
        body: {
          scenes,
          title: video.title,
          voice: video.voiceoverVoice,
          captionStyle: video.captionStyle,
          backgroundMusic: video.backgroundMusic
        }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        toast.error(jsonResonponse.message);
      } else {
        // toast.success(
        //   "Your video has been successfully added to the render queue.",
        //   {
        //     style: {
        //       top: "50px"
        //     }
        //   }
        // );

        setVideo(jsonResonponse.data.video);

        setScenes(jsonResonponse.data.scenes);
      }
    } catch (error) {
      // Capture the error message to display to the user
      console.error(error);
    }

    setIsSendingForRerender(false);
  };

  const canRerender =
    video &&
    (video.status === "failed" || hasChanges) &&
    video.status !== "queueing" &&
    video.status !== "generating" &&
    video.status !== "rendering";

  const isPageBusy = isVideoLoading || !video;

  return (
    <div className={styles.editorNav}>
      <div className={clsx("container-fluid", styles.navContainer)}>
        <Link to="/dashboard" className={styles.backBtn}>
          <SvgIcon name="arrow-left" className={styles.toggleRight} />
        </Link>

        <Link to="/dashboard" className={styles.logo}>
          <div className="invisible">Soutle</div>
        </Link>

        {isPageBusy ? (
          <div className={styles.navLoading}>
            <div>
              <div
                className={styles.buttonLoading}
                style={{ borderRadius: `4px`, width: "80px" }}
              ></div>
              {/* <div
                className={styles.buttonLoading}
                style={{ borderRadius: `4px`, width: "100px" }}
              ></div> */}
              <div
                className={styles.buttonLoading}
                style={{ borderRadius: `4px`, width: "420px" }}
              ></div>
            </div>
            <div>
              <div className={styles.buttonLoading}></div>
              <div className={styles.buttonLoading}></div>
            </div>
          </div>
        ) : (
          <>
            <div className={styles.controlWrap}>
              <SelectVideoSizeDropdown
                className={styles.selectVideoSize}
                value={size}
                onChange={handleVideoSizeChange}
              >
                <SvgIcon name="portrait" className={styles.playIcon} />
                <span className={styles.buttonLabel}>
                  Resize
                  <SvgIcon name="chevron-down" className="dropdown-chevron" />
                </span>
              </SelectVideoSizeDropdown>
            </div>

            {/* <div className={styles.controlWrap}>
              <SelectVoiceDropdown
                value={video.voiceoverVoice}
                disabled={isVideoBusy}
                onChange={handleVoiceChange}
              />
            </div> */}

            {isPageBusy ? (
              <div className={styles.titleLoading}>
                <div
                  className={styles.editorLoadingText}
                  style={{ width: "70%" }}
                ></div>
              </div>
            ) : (
              <div className={styles.inputWrap}>
                <input
                  id="vidTitle"
                  className={clsx("form-control", styles.titleInput)}
                  value={video.title}
                  maxLength={titleMaxLength}
                  autoComplete="off"
                  onChange={handleTitleChange}
                />
              </div>
            )}

            <div className={styles.rightButtons}>
              <AvailableCredits className={styles.availableCreditsWrap} />

              <span
                data-bs-toggle={!isVideoPlayable ? "tooltip" : null}
                data-bs-custom-class="white-tooltip"
                data-bs-placement="bottom"
                data-bs-title='Changes detected in your video. Click the "Render" button to rerender the video.'
              >
                <button
                  className={clsx("btn", styles.topNavButton)}
                  disabled={!isVideoPlayable}
                  onClick={playVideo}
                >
                  <SvgIcon name="play" className={styles.playIcon} />
                  <span className={styles.buttonLabel}>Play</span>
                </button>
              </span>
              <button
                className={clsx("btn", styles.topNavButton)}
                disabled={!canRerender || isSendingForRerender}
                onClick={handleRerender}
              >
                {isSendingForRerender || isVideoBusy ? (
                  <Spinner />
                ) : (
                  <>
                    <SvgIcon name="arrow-repeat" className={styles.playIcon} />{" "}
                    <span className={styles.buttonLabel}>Render</span>
                  </>
                )}
              </button>
              <ShareVideoDropdown
                video={video}
                className={clsx("btn", styles.topNavButton, styles.whiteBtn)}
                disabled={!isVideoPlayable}
              >
                <SvgIcon name="share" className={styles.playIcon} />

                <span className={styles.buttonLabel}>Share</span>
              </ShareVideoDropdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
