import { Modal } from "components/General/Modal";
import SvgIcon from "../SvgIcon";

import clsx from "clsx";
import Spinner from "../Spinner";
import { useConnectChannelPlatform } from "hooks/useConnectChannelPlatform";
import channelPlatforms from "support/channelPlatforms";

import "./style.scss";

interface ConnectChannelModalProps {
  show?: boolean;
  onClose?: () => void;
}

export function ConnectChannelModal({
  show = false,
  onClose
}: ConnectChannelModalProps) {
  const { connecting, handleConnectClick } = useConnectChannelPlatform();

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  return (
    <>
      <Modal
        show={show}
        className="connect-channel-modal"
        onClose={handleOnClose}
      >
        <h2>Connect a Channel</h2>

        <button type="button" className="btn-close" onClick={handleOnClose}>
          <SvgIcon name="close-circle" />
        </button>

        <div className="channels">
          {channelPlatforms.map(channel => (
            <div key={channel.id} className={clsx("channel-item")}>
              <div className="icon-wrap">
                <SvgIcon name={channel.icon} className="channel-icon" />
              </div>
              <div className="channel-name">{channel.name}</div>

              <button
                className={clsx("btn connect-btn", {
                  connecting: connecting === channel.id
                })}
                disabled={connecting === channel.id}
                onClick={() => handleConnectClick(channel)}
              >
                {connecting === channel.id ? <Spinner /> : "Connect"}
              </button>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
}
