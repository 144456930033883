import { Voice } from "./types";

export const voices: Voice[] = [
  {
    id: "felie",
    name: "Felie",
    description: "Warm, friendly, and engaging",
    tags: ["Young", "Male"],
    otherTags: ["man", "youth"],
    sample: "felie.wav"
  },
  {
    id: "aniey",
    name: "Aniey",
    description: "Lively, vibrant, and dynamic",
    tags: ["Young", "Female"],
    otherTags: ["woman", "lady", "girl"],
    sample: "aniey.wav"
  },
  {
    id: "belio",
    name: "Belio",
    description: "Neutral, professional, and clear",
    tags: ["Young", "Male"],
    otherTags: ["man", "youth"],
    sample: "belio.wav"
  },
  {
    id: "rutie",
    name: "Rutie",
    description: "Young, energetic, and engaging",
    tags: ["Young", "Female"],
    otherTags: ["woman", "lady", "girl"],
    sample: "rutie.wav"
  },
  {
    id: "pascio",
    name: "Pascio",
    description: "Energetic, expressive, and engaging",
    tags: ["Young", "Male"],
    otherTags: ["man", "boy"],
    sample: "pascio.wav"
  },
  {
    id: "chibbex",
    name: "Chibbex",
    description: "Older, mature, and experienced",
    tags: ["Old", "Male"],
    otherTags: ["man"],
    sample: "chibbex.wav"
  }
];