import clsx from "clsx";
import SvgIcon from "components/General/SvgIcon";

import { useBilling } from "contexts/BillingContext";
import { useAuth } from "contexts/AuthContext";

import styles from "./style.module.scss";

type AvailableCreditsProps = {
  className?: string;
};

export const AvailableCredits = (props: AvailableCreditsProps) => {
  const { showTopUpModal } = useBilling();
  const { user } = useAuth();

  const handleCreditClick = () => {
      showTopUpModal(); // Only show modal on larger screens
  };

  const credits = Math.round((user?.credits || 0) * 100) / 100;

  return (
    <div className={styles.availableCreditsWrap}>
     
        <div
          className={clsx(styles.availableCredits)}
          onClick={handleCreditClick}
        >
          <SvgIcon name="trophy" className={styles.trophyIcon} />
          {credits}
          <span className={styles.creditText}> Credits</span>
          <button className={styles.creditTopup} onClick={showTopUpModal}>
            Top up
          </button>
        </div>
      
    </div>
  );
};
