import { ZodEffects, ZodObject } from "zod";
import { FormEvent, useState } from "react";

import { ValidationErrors } from "support/types";

export default function useValidateForm<
  T extends ZodObject<any, any> | ZodEffects<ZodObject<any, any>>
>(schema: T) {
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validateForm = (target: HTMLFormElement) => {
    const data = new FormData(target);

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      // console.log(zResult.error.flatten().fieldErrors);
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  return {
    errors,
    setErrors,
    setIsPristine,
    validateForm,
    handleFormChange
  };
}
