import clsx from "clsx";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { MouseEvent, useEffect, useRef, useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import { ChannelSelect } from "components/General/ChannelSelect";

import { Channel } from "support/types";
import useBackend from "hooks/useBackend";
import { Video } from "support/types/videos";
import useConnectFbPages from "hooks/useConnectFbPages";
import useValidateForm from "hooks/useValidateForm";

import { ReactComponent as ShareVector } from "assets/vectors/share.svg";
import { useConnectChannelPlatform } from "hooks/useConnectChannelPlatform";

import channelPlatforms from "support/channelPlatforms";
import Spinner from "components/General/Spinner";
import { ConnectFacebookPagesModal } from "components/General/ConnectFacebookPagesModal";

import styles from "./style.module.scss";
import Inputfield from "components/General/InputField";
import toast from "support/toast";

interface ShareToFacebookProps {
  video: Video;
  className?: string;
  onChange?: (voiceId: string) => void;
  onBackClick?: () => void;
}

const ShareToFacebook = ({
  video,
  className,
  onBackClick
}: ShareToFacebookProps) => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState(true);
  const location = useLocation();
  const { connecting, handleConnectClick } = useConnectChannelPlatform(
    encodeURIComponent(`${location.pathname}?share=1&platform=facebook`)
  );
  const { isShowFbPagesModal, setIsShowConnectFbPagesModal } =
    useConnectFbPages();
  const formRef = useRef<HTMLFormElement | null>(null);

  const [isPosting, setIsPosting] = useState(false);
  const [isPosted, setIsPosted] = useState(false);

  const { get, post } = useBackend();

  const validation = z.object({
    channelId: z.string().min(1, { message: "Please choose a channel" }),
    title: z.string().min(1, { message: "Please enter a title" }),
    description: z.string().min(1, { message: "Please enter a description" }),
    isReel: z.preprocess(
      // Handle 'on'/undefined from form data as well as event objects
      val => {
        if (!!val && typeof val === "object" && "target" in val) {
          return (val as { target: { checked: boolean } }).target.checked;
        }
        // Handle 'on' or undefined from form data
        if (val === "on") return true;
        if (val === undefined) return false;

        return val;
      },
      z.boolean({
        required_error: "Reel status is required",
        invalid_type_error: "Reel status must be a boolean"
      })
    )
  });
  const { errors, setErrors, setIsPristine, validateForm, handleFormChange } =
    useValidateForm(validation);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsChannelsLoading(true);

    get(`/channels?platform=facebook`, { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setChannels(jsonResonponse.data.channels);
          } catch (error) {}
        }
        setIsChannelsLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsChannelsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get]);

  const handleOnBackClick = () => {
    if (typeof onBackClick === "function") {
      onBackClick();
    }
  };

  const handleOnFbPageAdd = (channel: Channel) => {
    setChannels([...channels, channel]);
  };

  const handleFormSubmit = async (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsPosting(true);

      try {
        const res = await post(`/videos/${video.id}/share/facebook`, {
          body: zResult.data
        });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          toast.error(jsonResonponse.message);

          // Reset scene
          setIsPosting(false);
        } else {
          console.log(jsonResonponse);
          setIsPosting(false);
          setIsPosted(true);
        }
      } catch (error) {
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  const facebookPlatform = channelPlatforms.find(p => p.id === "facebook");

  if (!facebookPlatform) {
    return null;
  }

  return (
    <div className={clsx(styles.facebookShareWrap, className)}>
      <div className={styles.shareHeader}>
        <button
          className={clsx("btn chevron-back-btn", styles.backButton)}
          onClick={() => handleOnBackClick()}
        >
          <SvgIcon name="chevron-left" />
        </button>
        Facebook Page
      </div>

      {isChannelsLoading && (
        <div className={styles.loadingWrap}>
          <div className={styles.selectPageLoading}></div>
          <div className={styles.titleLoading}></div>
          <div className={styles.descLoading}></div>
          <div className={styles.reelLoading}></div>
          <div className={styles.buttonLoading}></div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length > 0 && (
        <form
          ref={formRef}
          method="POST"
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
        >
          <Inputfield
            name="channelId"
            className="mb-3"
            errors={errors.channelId}
          >
            <ChannelSelect
              name="channelId"
              channels={channels}
              placeholder="Select a Facebook page"
              controlClassName={clsx({ "is-invalid": !!errors.channelId })}
              onSelect={() => validateForm(formRef?.current!)}
            />
          </Inputfield>

          <Inputfield
            name="title"
            label="Video title"
            className="mb-3"
            errors={errors.title}
            defaultValue={video.title}
          />

          <Inputfield
            name="description"
            type="textarea"
            label="Description"
            className="mb-3"
            errors={errors.description}
          />

          <Inputfield name="isReel" className="mb-3">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="shouldPostAsReel"
                name="isReel"
              />
              <label className="form-check-label" htmlFor="shouldPostAsReel">
                Post as Reel
              </label>
            </div>
          </Inputfield>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100 fw-bold"
              disabled={isPosting}
            >
              {isPosting ? <Spinner /> : "Publish"}
            </button>
          </div>
        </form>
      )}

      {!isChannelsLoading && isPosted && (
        <div className={styles.postedWrap}>
          <div className={styles.postedIconWrap}>
            <SvgIcon name="check" className={styles.postedIcon} />
          </div>

          <h4 className={styles.postedTitle}>
            Your video is now on its way to Facebook, it should be published
            shortly.
          </h4>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100"
              disabled={!!connecting}
              onClick={() => handleOnBackClick()}
            >
              <SvgIcon name="chevron-left" className={styles.postedBtnIcon} />{" "}
              Go back
            </button>
          </div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length < 1 && (
        <div className={styles.noPagesWrap}>
          <h4 className={styles.noPagesTitle}>
            Publish your videos straight to Facebook Pages!
          </h4>

          <div className={styles.noPagesIllustrationWrap}>
            <ShareVector />
            <div className={styles.noPagesIconWrap}>
              <SvgIcon name="facebook-colored" className={styles.noPagesIcon} />
            </div>
          </div>

          <h5>Connect your Facebook pages</h5>
          <p>
            Be sure to authorize all Pages you want to use in Soutle, including
            Facebook Pages linked to your Instagram accounts.
          </p>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100 fw-bold"
              disabled={!!connecting}
              onClick={() => handleConnectClick(facebookPlatform)}
            >
              {!!connecting ? <Spinner /> : "Connect Facebook"}
            </button>
          </div>
        </div>
      )}

      <ConnectFacebookPagesModal
        show={isShowFbPagesModal}
        onAdd={handleOnFbPageAdd}
        onClose={() => setIsShowConnectFbPagesModal(false)}
      />
    </div>
  );
};

export default ShareToFacebook;
