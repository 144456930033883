import { useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import Spinner from "components/General/Spinner";
import { Modal } from "components/General/Modal";

import { AuthUser, Plan } from "support/types";
import { getPlanPriceRate, getPriceTotal } from "support/helpers";
import { useAuth } from "contexts/AuthContext";
import toast from "support/toast";

import { setUser } from "store/reducers/authSlice";
import { useAppDispatch } from "hooks";
import useBackend from "hooks/useBackend";

import { useBilling } from "contexts/BillingContext";

import "./style.scss";

interface ConfirmSubChangeModalProps {
  show: boolean;
  frequency: string;
  newPlan: Plan | null;
  isStacked?: boolean;
  onClose?: () => void;
}

export function ConfirmSubChangeModal({
  show,
  frequency,
  newPlan,
  onClose
}: ConfirmSubChangeModalProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { userPlan } = useBilling();

  const { post } = useBackend();

  const changeSubscription = async () => {
    const newPlanPrice = newPlan?.prices.find(
      p => p.interval === frequency && p.currency === user?.currency
    );

    setIsLoading(true);

    try {
      const res = await post("/billing/plans/change", {
        body: { priceSlug: newPlanPrice?.slug }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsLoading(false);
      } else if (jsonResonponse.status === 200) {
        // Note: This endpoint when successful emits an subscriptionUpdated event
        // which is then handled by the BillingContext Provider.
        // Upaating user credits is handled in there.
        dispatch(
          setUser({
            ...user,
            subscription: {
              ...user?.subscription,
              planSlug: newPlan?.slug,
              priceSlug: newPlanPrice?.slug
            }
          } as AuthUser)
        );

        toast.success("Your subscription has been updated successfully.");

        setIsLoading(false);
        if (typeof onClose === "function") {
          onClose();
        }
      }
    } catch (error) {
      setIsLoading(false);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const userPlanPrice = userPlan?.prices.find(
    p => user?.subscription && p.slug === user?.subscription.priceSlug
  );

  let buttonText = "Upgrade";
  if (newPlan && userPlan) {
    if (
      newPlan.displayOrder < userPlan.displayOrder ||
      (newPlan.slug === userPlan.slug && frequency === "monthly")
    ) {
      buttonText = "Downgrade";
    }
  }

  const newPlanRate =
    newPlan && getPlanPriceRate(newPlan, frequency, user?.currency);

  const numFormatter = new Intl.NumberFormat("en-US");

  return (
    <Modal show={show} className="sub-change-modal" onClose={onClose}>
      <h5 className="modal-title">Confirm your subscription change</h5>
      <button type="button" className="btn-close" onClick={onClose}>
        <SvgIcon name="close-circle" />
      </button>

      <div className="change-summary">
        <h5>Current plan</h5>
        {userPlan && userPlanPrice && (
          <p>
            {userPlan.title} at {user?.currency}{" "}
            {numFormatter.format(
              getPriceTotal(userPlanPrice.amountMonthly, userPlanPrice.interval)
            )}{" "}
            / {userPlanPrice?.interval === "monthly" ? "month" : "year"}
          </p>
        )}
        <h5> New plan</h5>
        {newPlan && newPlanRate && (
          <p>
            {newPlan.title} at {user?.currency}{" "}
            {numFormatter.format(getPriceTotal(newPlanRate, frequency))} /{" "}
            {frequency === "monthly" ? "month" : "year"}
          </p>
        )}
      </div>
      <div className="text-end mt-4">
        <button
          className="btn btn-primary rounded-pill w-100 text-white py-2 px-4 fw-bold"
          disabled={isLoading}
          onClick={changeSubscription}
        >
          {!isLoading ? `${buttonText} & Pay` : <Spinner />}
        </button>
      </div>

      <div className="information border p-2 mt-4 rounded text-small">
        By subscribing, you agree to our <a href="#a">Terms of Service</a>.
        Subscriptions auto-renew until canceled, as described in the{" "}
        <a href="#a">Terms</a>. Cancel at least 24 hours prior to renewal to
        avoid additional charges. When{" "}
        {user?.currency === "USD" ? "downgrading" : "changing plans"}, your
        current subscription and the associated features will switch to the new
        subscription tier immediately. You will not receive a refund for any
        portion of your previous subscription, unless required by law. You will
        also be charged for the price of the new tier immediately.
      </div>
    </Modal>
  );
}
