import clsx from "clsx";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { MouseEvent, useEffect, useRef, useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import { ChannelSelect } from "components/General/ChannelSelect";

import { Channel } from "support/types";
import useBackend from "hooks/useBackend";
import { Video } from "support/types/videos";
import useValidateForm from "hooks/useValidateForm";

import { ReactComponent as ShareVector } from "assets/vectors/share.svg";
import { useConnectChannelPlatform } from "hooks/useConnectChannelPlatform";

import channelPlatforms from "support/channelPlatforms";
import Spinner from "components/General/Spinner";

import styles from "./style.module.scss";
import Inputfield from "components/General/InputField";
import toast from "support/toast";

interface ShareToInstagramProps {
  video: Video;
  className?: string;
  onChange?: (voiceId: string) => void;
  onBackClick?: () => void;
}

const ShareToInstagram = ({
  video,
  className,
  onBackClick
}: ShareToInstagramProps) => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState(true);
  const location = useLocation();
  const { connecting, handleConnectClick } = useConnectChannelPlatform(
    encodeURIComponent(`${location.pathname}?share=1&platform=instagram`)
  );
  const formRef = useRef<HTMLFormElement | null>(null);

  const [isPosting, setIsPosting] = useState(false);
  const [isPosted, setIsPosted] = useState(false);

  const { get, post } = useBackend();

  const validation = z.object({
    channelId: z.string().min(1, { message: "Please choose a channel" }),
    description: z.string().min(1, { message: "Please enter a description" })
  });
  const { errors, setErrors, setIsPristine, validateForm, handleFormChange } =
    useValidateForm(validation);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsChannelsLoading(true);

    get(`/channels?platform=instagram`, { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setChannels(jsonResonponse.data.channels);
          } catch (error) {}
        }
        setIsChannelsLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsChannelsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get]);

  const handleOnBackClick = () => {
    if (typeof onBackClick === "function") {
      onBackClick();
    }
  };

  const handleFormSubmit = async (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsPosting(true);

      try {
        const res = await post(`/videos/${video.id}/share/instagram`, {
          body: zResult.data
        });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          toast.error(jsonResonponse.message);

          // Reset scene
          setIsPosting(false);
        } else {
          console.log(jsonResonponse);
          setIsPosting(false);
          setIsPosted(true);
        }
      } catch (error) {
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  const instagramPlatform = channelPlatforms.find(p => p.id === "instagram");

  if (!instagramPlatform) {
    return null;
  }

  return (
    <div className={clsx(styles.instagramShareWrap, className)}>
      <div className={styles.shareHeader}>
        <button
          className={clsx("btn chevron-back-btn", styles.backButton)}
          onClick={() => handleOnBackClick()}
        >
          <SvgIcon name="chevron-left" />
        </button>
        Instagram
      </div>

      {isChannelsLoading && (
        <div className={styles.loadingWrap}>
          <div className={styles.selectPageLoading}></div>
          <div className={styles.descLoading}></div>
          <div className={styles.buttonLoading}></div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length > 0 && (
        <form
          ref={formRef}
          method="POST"
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
        >
          <Inputfield
            name="channelId"
            className="mb-3"
            errors={errors.channelId}
          >
            <ChannelSelect
              name="channelId"
              channels={channels}
              placeholder="Select a Instagram account"
              controlClassName={clsx({ "is-invalid": !!errors.channelId })}
              onSelect={() => validateForm(formRef?.current!)}
            />
          </Inputfield>

          <Inputfield
            name="description"
            type="textarea"
            label="Caption"
            className="mb-3"
            errors={errors.description}
            defaultValue={video.title}
          />

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100 fw-bold"
              disabled={isPosting}
            >
              {isPosting ? <Spinner /> : "Publish"}
            </button>
          </div>
        </form>
      )}

      {!isChannelsLoading && isPosted && (
        <div className={styles.postedWrap}>
          <div className={styles.postedIconWrap}>
            <SvgIcon name="check" className={styles.postedIcon} />
          </div>

          <h4 className={styles.postedTitle}>
            Your video is now on its way to Instagram, it should be published
            shortly.
          </h4>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100"
              disabled={!!connecting}
              onClick={() => handleOnBackClick()}
            >
              <SvgIcon name="chevron-left" className={styles.postedBtnIcon} />{" "}
              Go back
            </button>
          </div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length < 1 && (
        <div className={styles.noPagesWrap}>
          <h4 className={styles.noPagesTitle}>
            Publish your videos straight to Instagram!
          </h4>

          <div className={styles.noPagesIllustrationWrap}>
            <ShareVector />
            <div className={styles.noPagesIconWrap}>
              <SvgIcon
                name="instagram-colored"
                className={styles.noPagesIcon}
              />
            </div>
          </div>

          <h5>Connect your Instagram pages</h5>
          <p>You may need to convert your account to a professional account.</p>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100 fw-bold"
              disabled={!!connecting}
              onClick={() => handleConnectClick(instagramPlatform)}
            >
              {!!connecting ? <Spinner /> : "Connect Instagram"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareToInstagram;
