import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import alertController from "contexts/AlertContext/controller";

export default function useConnectFbPages() {
  const location = useLocation();
  const [isShowFbPagesModal, setIsShowConnectFbPagesModal] = useState(false);

  const [searchParams] = useSearchParams();
  const queryId = searchParams.get("id");

  const queryError = searchParams.get("error");
  const queryMessage = searchParams.get("message");

  const navigate = useNavigate();

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (
      query.get("connect") &&
      query.get("connect") === "facebook" &&
      !!queryId
    ) {
      setIsShowConnectFbPagesModal(true);
    }
  }, [queryId]);

  useEffect(() => {
    if (!!queryError && !!queryMessage) {
      alertController.open({
        icon: "info",
        title: "Error",
        message: queryMessage,
        onOkay: () => {
          navigate(location.pathname, { replace: true });
        }
      });
    }
  }, [queryError, queryMessage, navigate, location.pathname]);

  return {
    isShowFbPagesModal,
    setIsShowConnectFbPagesModal
  };
}
