import { useState } from "react";
import useBackend from "hooks/useBackend";
import toast from "support/toast";
import { ChannelPlatform } from "support/types";

export function useConnectChannelPlatform(backRoute?: string) {
  const [connecting, setConnecting] = useState<string | null>(null);

  const { get } = useBackend();

  const handleConnectClick = async (channel: ChannelPlatform) => {
    setConnecting(channel.id);

    try {
      const computedBackUrl = !!backRoute ? `?back=${backRoute}` : "";
      const res = await get(`/connect/${channel.id}${computedBackUrl}`);

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setConnecting(null);
      } else if (jsonResonponse.data.redirect) {
        window.location.href = jsonResonponse.data.redirect;
      }
    } catch (error) {
      setConnecting(null);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  return {
    connecting,
    handleConnectClick
  };
}
