import { z } from "zod";
import AuthSubmitButton from "components/Auth/AuthSubmitButton";
import PasswordField from "components/General/PasswordField";
import AuthTitle from "components/Auth/AuthTitle";
import AuthDescription from "components/Auth/AuthDescription";
import { FormEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "support/toast";
import Spinner from "components/General/Spinner";
import useBackend from "hooks/useBackend";

import sharedStyles from "../../layouts/Auth/shared.module.scss";
import useValidateForm from "hooks/useValidateForm";

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { token } = useParams();
  const navigate = useNavigate();

  const { post } = useBackend();

  const schema = z.object({
    password: z.string().min(1, { message: "Password is required" }),
    password_confirmation: z
      .string()
      .min(1, { message: "Password is required" }),
    token: z.string().min(1)
  });
  const { errors, setErrors, setIsPristine, validateForm, handleFormChange } =
    useValidateForm(schema);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/auth/reset-password", {
          body: zResult.data
        });

        const jsonResponse = await res.json();

        if (!res.ok) {
          if (jsonResponse.type === "validation") {
            setErrors(jsonResponse.errors);
          } else {
            toast.error(jsonResponse.message);
          }

          setIsLoading(false);
        } else if (jsonResponse.status === 200) {
          setIsLoading(false);
          navigate("/");
          toast.success("Password reset successful");
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  return (
    <>
      <AuthTitle>Reset your Password</AuthTitle>

      <AuthDescription>
        Please enter your new password below to continue.
      </AuthDescription>

      <form onSubmit={e => handleSubmit(e)} onChange={e => handleFormChange(e)}>
        <PasswordField
          icon="lock-alt"
          placeholder="New Password"
          className="my-4"
          inputClassName={sharedStyles.input}
          iconClassName={sharedStyles.inputIcon}
          name="password"
          errors={errors.password}
        />
        <PasswordField
          icon="lock-alt"
          placeholder="Confirm Password"
          className="my-4"
          inputClassName={sharedStyles.input}
          iconClassName={sharedStyles.inputIcon}
          name="password_confirmation"
          errors={errors.password_confirmation}
        />

        <input type="hidden" name="token" value={token} />

        <AuthSubmitButton>
          {!isLoading ? "Continue" : <Spinner />}
        </AuthSubmitButton>
      </form>
    </>
  );
}
