import Select, {
  SingleValue,
  components,
  Props as SelectProps,
  MenuListProps,
  MultiValue
} from "react-select";
import { useEffect, useState } from "react";

import SvgIcon from "components/General/SvgIcon";

import { PaymentMethod } from "support/types";
import { useBilling } from "contexts/BillingContext";

import "./style.scss";
import Spinner from "components/General/Spinner";

interface SelectPaymentMethodProps {
  id?: string;
  name?: string;
  required?: boolean;
  selected?: PaymentMethod;
  onSelect?: (pm: PaymentMethod) => void;
}

interface CustomSelectProps extends SelectProps<PaymentMethod, false> {
  hasMore: boolean;
  isLoadingMore: boolean;
  loadMoreOptions: () => void;
}

const CustomMenuList: React.FC<MenuListProps<PaymentMethod>> = props => {
  const { children, selectProps } = props;
  const { hasMore, loadMoreOptions, isLoadingMore } =
    selectProps as CustomSelectProps;

  return (
    <components.MenuList {...props}>
      {children}
      {hasMore && (
        <button
          type="button"
          className="btn btn-sm btn-light border w-100 mt-2"
          onClick={loadMoreOptions}
        >
          {isLoadingMore ? <Spinner color="primary" /> : "Load more"}
        </button>
      )}
    </components.MenuList>
  );
};

export function SelectPaymentMethod({
  id,
  name,
  required,
  selected,
  onSelect
}: SelectPaymentMethodProps) {
  const {
    paymentMethodsLoading,
    paymentMethods,
    loadPaymentMethods,
    hasMorePaymentMethods
  } = useBilling();

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const handleOnSelect = (
    singleValue: SingleValue<PaymentMethod> | MultiValue<PaymentMethod>
  ) => {
    if (typeof onSelect === "function") {
      onSelect(singleValue as PaymentMethod);
    }
  };

  const handleLoadMore = async () => {
    setIsLoadingMore(true);

    await loadPaymentMethods(paymentMethods[paymentMethods.length - 1].id);

    setIsLoadingMore(false);
  };

  useEffect(() => {
    loadPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatOptionLabel = (option: PaymentMethod) => (
    <div className="pm-item">
      <SvgIcon name={option.card.brand} className="pm-item-icon" />
      <div className="pm-item-details">
        <div className="pm-item-name">•••• {option.card.last4}</div>
        <div className="pm-item-expires">
          Expires {option.card.expMonth}/{option.card.expYear}
        </div>
      </div>
    </div>
  );

  return (
    <Select
      id={id}
      name={name}
      required={required}
      components={{ IndicatorSeparator: () => null, MenuList: CustomMenuList }}
      classNames={{
        control: state => (state.isFocused ? "rselect-focus" : "rselect"),
        option: state => {
          return state.isSelected ? "pm-option active" : "pm-option";
        }
      }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          height: 46
        }),
        menuList: basestyles => ({
          ...basestyles,
          padding: "8px"
        }),
        option: basestyles => ({
          ...basestyles,
          borderRadius: "8px"
        })
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 8,
        spacing: {
          ...theme.spacing,
          controlHeight: 46
        },
        colors: {
          ...theme.colors,
          primary25: "var(--bs-gray-100)",
          primary50: "var(--bs-gray-100)",
          primary: "var(--bs-primary)"
        }
      })}
      placeholder="Select a payment method"
      options={paymentMethods}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(option: PaymentMethod) => option.id}
      isLoading={paymentMethodsLoading}
      value={selected}
      onChange={handleOnSelect}
      noOptionsMessage={() => "No payment methods found"}
      // @ts-ignore
      hasMore={hasMorePaymentMethods}
      isLoadingMore={isLoadingMore}
      loadMoreOptions={handleLoadMore}
    />
  );
}
