import clsx from "clsx";
import { z } from "zod";
import { useLocation } from "react-router-dom";
import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";

import SvgIcon from "components/General/SvgIcon";
import { ChannelSelect } from "components/General/ChannelSelect";

import { Channel, TiktokCreator, TikTokPrivacyLevel } from "support/types";
import useBackend from "hooks/useBackend";
import { Video } from "support/types/videos";
import useValidateForm from "hooks/useValidateForm";

import { ReactComponent as ShareVector } from "assets/vectors/share.svg";
import { useConnectChannelPlatform } from "hooks/useConnectChannelPlatform";

import channelPlatforms from "support/channelPlatforms";
import Spinner from "components/General/Spinner";
import Inputfield from "components/General/InputField";
import Scrollbar from "components/General/Scrollbar";

import useTooltip from "hooks/useTooltip";
import toast from "support/toast";

import styles from "./style.module.scss";

interface ShareToTiktokProps {
  video: Video;
  className?: string;
  onChange?: (voiceId: string) => void;
  onBackClick?: () => void;
}

const privacyNameMap: Record<TikTokPrivacyLevel, string> = {
  PUBLIC_TO_EVERYONE: "Public",
  FOLLOWER_OF_CREATOR: "Followers",
  MUTUAL_FOLLOW_FRIENDS: "Mutual friends",
  SELF_ONLY: "Private"
};

const ShareToTiktok = ({
  video,
  className,
  onBackClick
}: ShareToTiktokProps) => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState(true);
  const location = useLocation();
  const { connecting, handleConnectClick } = useConnectChannelPlatform(
    encodeURIComponent(`${location.pathname}?share=1&platform=tiktok`)
  );
  const [creator, setCreator] = useState<TiktokCreator | null>(null);
  const [isCreatorLoading, setIsCreatorLoading] = useState(false);

  const formRef = useRef<HTMLFormElement | null>(null);

  const [privacyLevel, setPrivacyLevel] = useState<string | undefined>();
  const [isDiscloseContent, setIsDiscloseContent] = useState(false);
  const [isBrandedContent, setIsBrandedContent] = useState(false);
  const [isYourBrand, setIsYourBrand] = useState(false);

  const [isPosting, setIsPosting] = useState(false);
  const [isPosted, setIsPosted] = useState(false);

  const { get, post } = useBackend();

  useTooltip();

  const validateBoolean = z.preprocess(
    // Handle 'on'/undefined from form data as well as event objects
    val => {
      if (!!val && typeof val === "object" && "target" in val) {
        return (val as { target: { checked: boolean } }).target.checked;
      }
      // Handle 'on' or undefined from form data
      if (val === "on") return true;
      if (val === undefined) return false;

      return val;
    },
    z.boolean({
      required_error: "Reel status is required",
      invalid_type_error: "Reel status must be a boolean"
    })
  );

  const validation = z.object({
    channelId: z.string().min(1, { message: "Please choose a channel" }),
    title: z.string().min(1, { message: "Please enter a title" }),
    privacyLevel: z.enum(
      [
        "PUBLIC_TO_EVERYONE",
        "FOLLOWER_OF_CREATOR",
        "MUTUAL_FOLLOW_FRIENDS",
        "SELF_ONLY"
      ],
      {
        errorMap: (issue, ctx) => {
          return {
            message: `Please select content visibilty for this video`
          };
        }
      }
    ),
    allowComment: validateBoolean,
    allowDuet: validateBoolean,
    allowStitch: validateBoolean,
    isBrandOrganic: validateBoolean,
    isBrandContent: validateBoolean
  });
  const { errors, setErrors, setIsPristine, validateForm, handleFormChange } =
    useValidateForm(validation);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsChannelsLoading(true);

    get(`/channels?platform=tiktok`, { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setChannels(jsonResonponse.data.channels);
          } catch (error) {}
        }
        setIsChannelsLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsChannelsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get]);

  const handleOnBackClick = () => {
    if (typeof onBackClick === "function") {
      onBackClick();
    }
  };

  const loadCreator = (channel: Channel) => {
    setIsCreatorLoading(true);

    get(`/videos/${video.id}/share/${channel.id}/creator`)
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setCreator(jsonResonponse.data.creator);
          } catch (error) {}
        }
        setIsCreatorLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsCreatorLoading(false);
        }
      });
  };

  const handleOnChannelSelect = (channel: Channel) => {
    validateForm(formRef?.current!);

    if (!!channel) {
      loadCreator(channel);
    }
  };

  const handleFormSubmit = async (e: MouseEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsPosting(true);

      try {
        const res = await post(`/videos/${video.id}/share/tiktok`, {
          body: zResult.data
        });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          toast.error(jsonResonponse.message);

          // Reset scene
          setIsPosting(false);
        } else {
          console.log(jsonResonponse);
          setIsPosting(false);
          setIsPosted(true);
        }
      } catch (error) {
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  const handleOnDiscloseContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsDiscloseContent(e.target.checked);

    if (!e.target.checked) {
      setIsYourBrand(false);
      setIsBrandedContent(false);
    }
  };

  const handleOnIsBrandedContentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsBrandedContent(e.target.checked);

    if (privacyLevel === "SELF_ONLY") {
      setPrivacyLevel("");
      toast.error("Branded content visibility cannot be set to private.");
    }
  };

  const privacyOptions = creator?.privacyLevelOptions.map(
    (option: TikTokPrivacyLevel) => ({
      value: option,
      name: privacyNameMap[option],
      disabled: option === "SELF_ONLY" && isBrandedContent
    })
  );

  const tiktokPlatform = channelPlatforms.find(p => p.id === "tiktok");

  if (!tiktokPlatform) {
    return null;
  }

  return (
    <div className={clsx(styles.tiktokShareWrap, className)}>
      <div className={styles.shareHeader}>
        <button
          className={clsx("btn chevron-back-btn", styles.backButton)}
          onClick={() => handleOnBackClick()}
        >
          <SvgIcon name="chevron-left" />
        </button>
        Tiktok
      </div>

      {isChannelsLoading && (
        <div className={styles.loadingWrap}>
          <div className={styles.selectPageLoading}></div>
          <div className={styles.descLoading}></div>
          <div className={styles.buttonLoading}></div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length > 0 && (
        <form
          ref={formRef}
          method="POST"
          onSubmit={handleFormSubmit}
          onChange={handleFormChange}
        >
          <Scrollbar className={styles.tiktokShareScroll}>
            <Inputfield
              name="channelId"
              className="mb-3"
              errors={errors.channelId}
            >
              <ChannelSelect
                name="channelId"
                channels={channels}
                placeholder="Select a Tiktok account"
                controlClassName={clsx({ "is-invalid": !!errors.channelId })}
                onSelect={handleOnChannelSelect}
              />
            </Inputfield>
            <Inputfield
              name="title"
              type="textarea"
              label="Caption"
              className="mb-3"
              defaultValue={video.title}
              errors={errors.title}
            />
            {!isCreatorLoading && !!creator && (
              <>
                <Inputfield
                  name="privacyLevel"
                  label="Who can see this video"
                  className="mb-3"
                  errors={errors.privacyLevel}
                >
                  <select
                    className="form-select"
                    aria-label="Privacy level"
                    name="privacyLevel"
                    disabled={!!!creator}
                    value={privacyLevel}
                    onChange={e => setPrivacyLevel(e.target.value)}
                  >
                    <option value={undefined}>Select visibility</option>
                    {privacyOptions?.map(level => (
                      <option
                        key={level.value}
                        value={level.value}
                        disabled={level.disabled}
                      >
                        {level.name}
                        {isBrandedContent && level.value === "SELF_ONLY"
                          ? " - Branded content visibility cannot be set to private."
                          : ""}
                      </option>
                    ))}
                  </select>
                </Inputfield>
                <label className={clsx("mb-1", styles.headingLabel)}>
                  Allow users to
                </label>
                <div className={styles.allowedActions}>
                  <Inputfield name="allowComment" className="mb-3">
                    <div className="form-check form-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="shouldAllowComment"
                        name="allowComment"
                        disabled={creator.commentDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="shouldAllowComment"
                      >
                        Comment
                      </label>
                    </div>
                  </Inputfield>
                  <Inputfield name="allowDuet" className="mb-3">
                    <div className="form-check form-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="shouldAllowDuet"
                        name="allowDuet"
                        disabled={creator.duetDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="shouldAllowDuet"
                      >
                        Duet
                      </label>
                    </div>
                  </Inputfield>
                  <Inputfield name="allowStitch" className="mb-3">
                    <div className="form-check form-checkbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="shouldAllowStitch"
                        name="allowStitch"
                        disabled={creator.stitchDisabled}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="shouldAllowStitch"
                      >
                        Stitch
                      </label>
                    </div>
                  </Inputfield>
                </div>
                <div className="d-flex justify-content-between">
                  <label
                    className={styles.headingLabel}
                    htmlFor="discloseVideoContent"
                  >
                    Disclose video content
                  </label>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="discloseVideoContent"
                      checked={isDiscloseContent}
                      onChange={handleOnDiscloseContentChange}
                    />
                  </div>
                </div>

                {(isYourBrand || isBrandedContent) && (
                  <div
                    className="alert alert-primary d-flex align-items-start mb-1"
                    role="alert"
                  >
                    <SvgIcon name="info-circle" className={styles.alertIcon} />
                    <div className={styles.explanation}>
                      Your video will be labeled as{" "}
                      {isBrandedContent
                        ? '"Paid partnership"'
                        : '"Promotional content"'}
                      . This cannot be changed once your video is posted.
                    </div>
                  </div>
                )}

                <p className={styles.explanation}>
                  Turn on to disclose that this video promotes goods or services
                  in exchange for something of value. Your video could promote
                  yourself, a third party, or both.
                </p>
                {isDiscloseContent && (
                  <>
                    <div className="d-flex justify-content-between">
                      <label
                        className={styles.headingLabel}
                        htmlFor="yourBrand"
                      >
                        Your brand
                      </label>
                      <div className="form-check form-switch">
                        <input
                          name="isBrandOrganic"
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="yourBrand"
                          checked={isYourBrand}
                          onChange={() => setIsYourBrand(!isYourBrand)}
                        />
                      </div>
                    </div>
                    <p className={styles.explanation}>
                      You are promoting yourself or your own business. This
                      video will be classified as Brand Organic.
                    </p>
                    <div
                      className="d-flex justify-content-between"
                      data-bs-custom-class="white-tooltip"
                      data-bs-toggle={
                        privacyLevel === "SELF_ONLY" ? "tooltip" : null
                      }
                      data-bs-placement="top"
                      data-bs-title="Branded content visibility cannot be set to private."
                    >
                      <label
                        className={styles.headingLabel}
                        htmlFor="brandedContent"
                      >
                        Branded content
                      </label>
                      <div className="form-check form-switch">
                        <input
                          name="isBrandContent"
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="brandedContent"
                          checked={isBrandedContent}
                          onChange={handleOnIsBrandedContentChange}
                          disabled={privacyLevel === "SELF_ONLY"}
                        />
                      </div>
                    </div>
                    <p className={styles.explanation}>
                      You are promoting another brand or a third party. This
                      video will be classified as Branded Content.
                    </p>
                  </>
                )}
                <p className={clsx("mb-0", styles.explanation)}>
                  By posting, you agree to TikTok's{" "}
                  {isBrandedContent && (
                    <>
                      <a
                        href="https://www.tiktok.com/legal/page/global/bc-policy/en"
                        target="_blank"
                        rel="nofollow noreferrer"
                      >
                        Branded Content Policy
                      </a>{" "}
                      and{" "}
                    </>
                  )}
                  <a
                    href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Music Usage Confirmation
                  </a>
                  .
                </p>
              </>
            )}
            {isCreatorLoading && (
              <>
                <div className={styles.titleLoading}></div>
                <div className={styles.titleLoading}></div>
                <div className={styles.titleLoading}></div>
                <div className={styles.titleLoading}></div>
                <div className={styles.titleLoading}></div>
              </>
            )}
          </Scrollbar>
          <div className={styles.shareFooter}>
            <span
              data-bs-custom-class="white-tooltip"
              data-bs-toggle={
                isDiscloseContent && !isYourBrand && !isBrandedContent
                  ? "tooltip"
                  : null
              }
              data-bs-placement="bottom"
              data-bs-title="You need to indicate if your content promotes yourself, a third party, or both."
            >
              <button
                className="btn btn-primary text-white w-100 fw-bold"
                disabled={
                  !!!creator ||
                  isPosting ||
                  (isDiscloseContent && !isYourBrand && !isBrandedContent)
                }
              >
                {isPosting ? <Spinner /> : "Publish"}
              </button>
            </span>
          </div>
        </form>
      )}

      {!isChannelsLoading && isPosted && (
        <div className={styles.postedWrap}>
          <div className={styles.postedIconWrap}>
            <SvgIcon name="check" className={styles.postedIcon} />
          </div>

          <h4 className={styles.postedTitle}>
            Your video is now on its way to Tiktok, it may take a few minutes
            for it to process and be visible on your profile.
          </h4>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100"
              disabled={!!connecting}
              onClick={() => handleOnBackClick()}
            >
              <SvgIcon name="chevron-left" className={styles.postedBtnIcon} />{" "}
              Go back
            </button>
          </div>
        </div>
      )}

      {!isPosted && !isChannelsLoading && channels.length < 1 && (
        <div className={styles.noPagesWrap}>
          <h4 className={styles.noPagesTitle}>
            Publish your videos straight to Tiktok!
          </h4>

          <div className={styles.noPagesIllustrationWrap}>
            <ShareVector />
            <div className={styles.noPagesIconWrap}>
              <SvgIcon name="tiktok-colored" className={styles.noPagesIcon} />
            </div>
          </div>

          <h5>Connect your Tiktok account</h5>
          <p>
            You'll be redirected to TikTok to complete the authentication
            process in the next step.
          </p>

          <div className="mb-3">
            <button
              className="btn btn-primary text-white w-100 fw-bold"
              disabled={!!connecting}
              onClick={() => handleConnectClick(tiktokPlatform)}
            >
              {!!connecting ? <Spinner /> : "Connect Tiktok"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareToTiktok;
