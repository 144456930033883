import clsx from "clsx";
import { useEffect, useState } from "react";

import { useVideoEditor } from "contexts/VideoEditorContext";

import NavButtons from "./Buttons";
import NavContexts from "./Contexts";

import styles from "./style.module.scss";

type SideNavProps = {
  className?: string;
};

export default function SideNav(props: SideNavProps) {
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const { isSideNavCollapsed, setIsSideNavCollapsed } = useVideoEditor();

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
    setIsSideNavCollapsed(false);
  };

  const handleToggleCollapse = () => {
    setActiveTab(null);
    setIsSideNavCollapsed(true);
  };

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      const isMobile = window.innerWidth < 991;
      setActiveTab(!isMobile ? "script" : null);
      setIsSideNavCollapsed(isMobile);
    };

    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={clsx(styles.sideNavWrap, {
        [styles.open]: !isSideNavCollapsed
      })}
    >
      <NavButtons
        active={activeTab}
        onChange={tabName => handleTabChange(tabName)}
      />

      <NavContexts
        active={activeTab}
        collapse={isSideNavCollapsed}
        onToggleCollapse={() => handleToggleCollapse()}
      />
    </div>
  );
}
