import  { useState, useEffect } from "react";
import clsx from "clsx";
import { Outlet, useMatch } from "react-router-dom";

import Navbar from "../../components/Dashboard/Navbar";
import SideNav from "../../components/Dashboard/SideNav";

import styles from "./style.module.scss";

export default function DashboardLayout() {
  const [isSideNavOpen, setIsSideNavOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

  const toggleSideNav = () => {
    setIsSideNavOpen(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setIsSideNavOpen(false);
        setIsMobile(true);
      } else {
        setIsSideNavOpen(true);
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={clsx(styles.dashboardLayout, {
        [styles.fullWidth]: !isSideNavOpen,
      })}
    >
      <SideNav
        className={clsx(styles.sideNav, {
          [styles.isOpen]: isSideNavOpen,
        })}
        setIsSideNavOpen={setIsSideNavOpen}
      />

      {/* Backdrop for when SideNav is open */}
      {isSideNavOpen && isMobile && (
        <div
          className={styles.backdrop}
          onClick={() => setIsSideNavOpen(false)}
        ></div>
      )}

      <main
        className={clsx(styles.main, { [styles.expandedMain]: !isSideNavOpen })}
        onClick={() => {
          if (window.innerWidth <= 992) {
            setIsSideNavOpen(false);
          }
        }}
      >
        <Navbar toggleSideNav={toggleSideNav} />

        <div
          className={clsx("container", styles.container, {
            [styles.editContainerClass]: !!useMatch("/videos/:id"),
          })}
        >
          <Outlet />
        </div>
      </main>
    </div>
  );
}
