import clsx from "clsx";

import SvgIcon from "components/General/SvgIcon";
import Scrollbar from "components/General/Scrollbar";

import styles from "./style.module.scss";

interface NavButtonsProp {
  active?: string | null;
  onChange?: (
    tabName: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
}

const navs = [
  { name: "script", label: "Script", icon: "content" },
  { name: "voiceover", label: "Voiceover", icon: "voice-circle" },
  { name: "style", label: "Style", icon: "layout" },
  { name: "sound", label: "Sound", icon: "music-note" },
  { name: "images", label: "Image", icon: "assets" },
  { name: "settings", label: "Settings", icon: "settings" }
];

export default function NavButtons(props: NavButtonsProp) {
  const handleSwitchTab = (
    tabName: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    props.onChange && props.onChange(tabName, e);
  };

  return (
    <Scrollbar className={styles.sideNavButtonsWrap}>
      <div className={styles.sideNavButtons}>
        {navs.map(nav => (
          <button
            key={nav.name}
            className={clsx(styles.sideNavButton, {
              [styles.activeButton]: props.active === nav.name
            })}
            onClick={e => handleSwitchTab(nav.name, e)}
          >
            <SvgIcon name={nav.icon} className={styles.navIcon} />
            {nav.label}
          </button>
        ))}
      </div>
    </Scrollbar>
  );
}
