import clsx from "clsx";
import Select, {
  SingleValue,
  components,
  Props as SelectProps,
  MenuListProps,
  MultiValue
} from "react-select";

import { Channel } from "support/types";
import Spinner from "components/General/Spinner";
import { capitalizeFirstLetter } from "support/helpers";

import styles from "./style.module.scss";

interface ChannelSelectProps {
  id?: string;
  name?: string;
  className?: string;
  controlClassName?: string;
  required?: boolean;
  channels: Channel[];
  selected?: Channel;
  isLoading?: boolean;
  hasMore?: boolean;
  isLoadingMore?: boolean;
  placeholder?: string;
  onSelect?: (channel: Channel) => void;
  onLoadMoreClick?: () => void;
}

interface CustomSelectProps extends SelectProps<Channel, false> {
  hasMore: boolean;
  isLoadingMore: boolean;
  loadMoreOptions: () => void;
}

const CustomMenuList: React.FC<MenuListProps<Channel>> = props => {
  const { children, selectProps } = props;
  const { hasMore, loadMoreOptions, isLoadingMore } =
    selectProps as CustomSelectProps;

  return (
    <components.MenuList {...props}>
      {children}
      {hasMore && (
        <button
          type="button"
          className="btn btn-sm btn-light border w-100 mt-2"
          onClick={loadMoreOptions}
        >
          {isLoadingMore ? <Spinner color="primary" /> : "Load more"}
        </button>
      )}
    </components.MenuList>
  );
};

export function ChannelSelect({
  id,
  className,
  controlClassName,
  name,
  required,
  channels,
  selected,
  isLoading,
  onSelect,
  hasMore,
  placeholder = "Select a Channel",
  isLoadingMore,
  onLoadMoreClick
}: ChannelSelectProps) {
  const handleOnSelect = (
    singleValue: SingleValue<Channel> | MultiValue<Channel>
  ) => {
    if (typeof onSelect === "function") {
      onSelect(singleValue as Channel);
    }
  };

  const handleLoadMore = async () => {
    if (typeof onLoadMoreClick === "function") {
      onLoadMoreClick();
    }
  };

  const formatOptionLabel = (option: Channel) => (
    <div className={styles.channelItem}>
      <img
        src={option.photoUrl}
        height={30}
        width={30}
        alt=""
        className={styles.channelImage}
      />
      <div className={styles.channelDetails}>
        <div className={styles.channelName}>{option.name}</div>
        <div className={styles.channelType}>
          {capitalizeFirstLetter(option.platform)} {option.type}
        </div>
      </div>
    </div>
  );

  return (
    <Select
      id={id}
      name={name}
      className={className}
      required={required}
      components={{ IndicatorSeparator: () => null, MenuList: CustomMenuList }}
      classNames={{
        control: state =>
          clsx(
            state.isFocused ? "rselect-focus" : "rselect",
            styles.control,
            controlClassName
          ),
        option: state => {
          return clsx(styles.channelOption, { active: state.isSelected });
        }
      }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          height: 46
        }),
        menuList: basestyles => ({
          ...basestyles,
          padding: "8px"
        }),
        option: basestyles => ({
          ...basestyles,
          borderRadius: "8px"
        })
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 8,
        spacing: {
          ...theme.spacing,
          controlHeight: 46
        },
        colors: {
          ...theme.colors,
          primary25: "var(--bs-gray-100)",
          primary50: "var(--bs-gray-100)",
          primary: "var(--bs-primary)"
        }
      })}
      placeholder={placeholder}
      options={channels}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(option: Channel) => option.id}
      isLoading={isLoading}
      value={selected}
      onChange={handleOnSelect}
      noOptionsMessage={() => "No Channels found"}
      // @ts-ignore
      hasMore={hasMore}
      isLoadingMore={isLoadingMore}
      loadMoreOptions={handleLoadMore}
    />
  );
}
