import clsx from "clsx";

import styles from "./style.module.scss";

import SvgIcon from "components/General/SvgIcon";

export type InputFieldProps = {
  children?: React.ReactNode;
  prefix?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  iconClassName?: string;
  label?: string;
  id?: string;
  name?: string;
  icon?: string;
  placeholder?: string;
  type?: string;
  tip?: string;
  errors?: string[] | undefined;
  defaultValue?: string;
};

export default function Inputfield({
  type = "text",
  ...props
}: InputFieldProps) {
  return (
    <div className={clsx(styles.inputField, props.className)}>
      {!!props.label && (
        <label htmlFor={props.id} className="form-label">
          {props.label}
        </label>
      )}
      {!!props.children ? (
        props.children
      ) : (
        <div className={styles.inputWrap}>
          {props.icon && (
            <SvgIcon
              name={props.icon}
              className={clsx(styles.inputIcon, props.iconClassName)}
            />
          )}
          {type === "textarea" ? (
            <textarea
              id={props.id}
              name={props.name}
              className={clsx(
                "form-control",
                styles.input,
                props.inputClassName,
                {
                  "input-has-icon": !!props.icon,
                  "is-invalid": !!props.errors
                }
              )}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
              rows={2}
            ></textarea>
          ) : (
            <input
              id={props.id}
              name={props.name}
              type={type}
              className={clsx(
                "form-control",
                styles.input,
                props.inputClassName,
                {
                  "input-has-icon": !!props.icon,
                  "is-invalid": !!props.errors
                }
              )}
              placeholder={props.placeholder}
              defaultValue={props.defaultValue}
            />
          )}

          {props.prefix}
        </div>
      )}
      {props.tip && <div className="form-text">{props.tip}</div>}
      {props.errors
        ? props.errors.map((message, i) => (
            <div
              key={i + 1}
              className={clsx("invalid-feedback", styles.showInvalidFeedback)}
            >
              {message}
            </div>
          ))
        : null}
    </div>
  );
}
