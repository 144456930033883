import { z } from "zod";
import { Link } from "react-router-dom";

import styles from "./style.module.scss";
import sharedStyles from "../../layouts/Auth/shared.module.scss";

import AuthSubmitButton from "components/Auth/AuthSubmitButton";
import InputField from "components/General/InputField";
import AuthTitle from "components/Auth/AuthTitle";
import AuthDescription from "components/Auth/AuthDescription";
import React, { FormEvent, useState } from "react";
import toast from "support/toast";
import Spinner from "components/General/Spinner";
import useBackend from "hooks/useBackend";
import useValidateForm from "hooks/useValidateForm";

export default function Forgot() {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { post } = useBackend();

  const schema = z.object({
    email: z.string().email({ message: "Please enter a valid email." })
  });
  const { errors, setErrors, setIsPristine, validateForm, handleFormChange } =
    useValidateForm(schema);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e.currentTarget);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/auth/forgot-password", {
          body: zResult.data
        });

        const jsonResponse = await res.json();

        if (!res.ok) {
          if (jsonResponse.type === "validation") {
            setErrors(jsonResponse.errors);
          } else {
            toast.error(jsonResponse.message);
          }

          setIsLoading(false);
        } else if (jsonResponse.status === 200) {
          if (formRef.current) {
            formRef.current.reset();
          }
          setIsLoading(false);
          toast.success(
            "We have just sent a password reset link to the provided email"
          );
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };
  return (
    <>
      <AuthTitle>Forgot Password?</AuthTitle>

      <AuthDescription>
        Please enter the email address associated with your account and
        we&apos;ll send you instructions on how to reset your password.
      </AuthDescription>

      <form
        onSubmit={e => handleSubmit(e)}
        onChange={e => handleFormChange(e)}
        ref={formRef}
      >
        <InputField
          name="email"
          icon="email-at"
          placeholder="Email Address"
          className="my-4"
          inputClassName={sharedStyles.input}
          iconClassName={sharedStyles.inputIcon}
          errors={errors.email}
        />

        <AuthSubmitButton>
          {!isLoading ? "Continue" : <Spinner />}
        </AuthSubmitButton>
      </form>

      <p className={styles.orLogin}>
        Remember your credentials? <Link to="/">Login</Link>.
      </p>
    </>
  );
}
