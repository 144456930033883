import { useEffect, useState } from "react";

import useBackend from "./useBackend";
import { Channel } from "support/types";

export default function useChannelList() {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [isChannelsLoading, setIsChannelsLoading] = useState(false);

  const { get } = useBackend();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsChannelsLoading(true);

    get(`/channels`, { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setChannels(jsonResonponse.data.channels);
          } catch (error) {}
        }
        setIsChannelsLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsChannelsLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get]);

  return {
    channels,
    setChannels,
    isChannelsLoading
  };
}
