import InitialsAvatar from "components/General/InitialsAvatar";

import { useAuth } from "contexts/AuthContext";

import styles from "./style.module.scss";
import { z } from "zod";
import useBackend from "hooks/useBackend";
import { FormEvent, useState } from "react";
import { ValidationErrors } from "support/types";
import SubmitButton from "components/General/SubmitButton";
import Spinner from "components/General/Spinner";
import alertController from "contexts/AlertContext/controller";

export default function AccountSettings() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  const { put } = useBackend();
  const { user } = useAuth();

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);
    const schema = z.object({
      firstName: z
        .string()
        .min(1, { message: "The First Name field is required" }),
      lastName: z
        .string()
        .min(1, { message: "The Last Name field is required" }),
    });
    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) return;

    const zResult = validateForm(e);
    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsPristine(false);

    const zResult = validateForm(e);
    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
      return;
    }

    setIsLoading(true);
    try {
      const response = await put("/users/edit-profile", {
        body: zResult.data,
      });
      setIsLoading(false);

      if (response.ok) {
        alertController.open({
          icon: "success",
          message: "Profile updated successfully!",
        });

        setIsPristine(true);
        setErrors({});
      } else {
        const errorData = await response.json();
        const serverErrorMessage =
          errorData?.message || `Request failed with status ${response.status}`;
        throw new Error(serverErrorMessage);
      }
    } catch (error: any) {
      setIsLoading(false);
      alertController.open({
        icon: "error",
        message: error.message || "Failed to update profile. Please try again.",
      });
      console.error(error);
    }
  };

  return (
    <div className={styles.accountSettings}>
      <h3>Account Settings</h3>
      <p>Keep your account information up to date.</p>

      <div className="d-flex justify-content-center my-4">
        <div className={styles.avatarWrap}>
          <InitialsAvatar
            name={`${user?.firstName} ${user?.lastName}`}
            size={120}
          />
          {/* <button className={clsx("btn", styles.updatePhotoBtn)}>
            <SvgIcon name="camera" />
          </button> */}
        </div>
      </div>

      <form onSubmit={handleSubmit} onChange={handleFormChange}>
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="mb-3">
              <label htmlFor="firstName" className="form-label">
                First Name
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.firstName ? "is-invalid" : ""
                }`}
                id="firstName"
                name="firstName"
                defaultValue={user?.firstName}
              />
              {errors.firstName && (
                <div className="invalid-feedback">{errors.firstName[0]}</div>
              )}
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="mb-3">
              <label htmlFor="lastName" className="form-label">
                Last Name
              </label>
              <input
                type="text"
                className={`form-control ${
                  errors.lastName ? "is-invalid" : ""
                }`}
                id="lastName"
                name="lastName"
                defaultValue={user?.lastName}
              />
              {errors.lastName && (
                <div className="invalid-feedback">{errors.lastName[0]}</div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="mb-4">
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                defaultValue={user?.email}
                className="form-control"
                id="email"
                aria-describedby="emailHelp"
                readOnly
                disabled
              />
              <div id="emailHelp" className="form-text">
                Please contact our support team to update your email address.
              </div>
            </div>
          </div>
        </div>

        <div className="text-end">
          <SubmitButton
            className="btn btn-primary text-white px-5 rounded-pill"
            disabled={isLoading}
          >
            {isLoading ? <Spinner size="sm" color="light" /> : "Update Account"}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
}
