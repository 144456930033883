import Select, { SingleValue } from "react-select";
import { Currency } from "support/types";
import currencies from "support/currencies";

import "./style.scss";
import clsx from "clsx";

interface SelectCurrencyProps {
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  width?: number;
  height?: number;
  required?: boolean;
  disabled?: boolean;
  selected?: Currency;
  onChange?: (curr: Currency) => void;
}

export default function SelectCurrency({
  selected,
  className,
  width,
  height,
  disabled = false,
  onChange
}: SelectCurrencyProps) {
  const formatOptionLabel = (option: Currency) => (
    <div className="curr-item">
      <div className="curr-item-details">
        <div className="curr-item-name">{option.code}</div>
        {/* <div className="curr-item-expires">{option.name}</div> */}
      </div>
    </div>
  );

  const handleOnSelect = async (singleValue: SingleValue<Currency>) => {
    if (typeof onChange === "function") {
      onChange(singleValue as Currency);
    }
  };

  return (
    <Select
      name="currency"
      required
      isDisabled={disabled}
      components={{
        IndicatorSeparator: () => null
      }}
      classNames={{
        control: state =>
          state.isFocused ? "rselect-focus" : clsx("rselect", className),
        option: state => {
          return state.isSelected ? "curr-option active" : "curr-option";
        }
      }}
      styles={{
        control: baseStyles => ({
          ...baseStyles,
          height,
          width
        }),
        menu: basestyles => ({
          ...basestyles,
          width
        }),
        menuList: basestyles => ({
          ...basestyles,
          padding: "8px"
        }),
        option: basestyles => ({
          ...basestyles,
          borderRadius: "8px"
        })
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 8,
        spacing: {
          ...theme.spacing,
          controlHeight: height || theme.spacing.controlHeight
        },
        colors: {
          ...theme.colors,
          primary25: "var(--bs-gray-100)",
          primary50: "var(--bs-gray-100)",
          primary: "var(--bs-primary)"
        }
      })}
      placeholder="Select currency"
      options={currencies}
      formatOptionLabel={formatOptionLabel}
      getOptionValue={(option: Currency) => option.code}
      value={selected}
      noOptionsMessage={() => "No currencies found"}
      onChange={handleOnSelect}
    />
  );
}
