import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useCallback
} from "react";

import useBackend from "hooks/useBackend";

interface AppContextProps {
  isAppDataLoading: boolean;
  creditUnitPrice: number;
  ngnUsdRate: number;
  isGenerateInputVisible: boolean;
  setIsGenerateInputVisible: (visible: boolean) => void;
  shouldFocusGenerateInput: boolean;
  setShouldFocusGenerateInput: (visible: boolean) => void;
  fetchInitData: (signal?: AbortSignal | undefined) => Promise<void>;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const useApp = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useApp must be used within a AppProvider");
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [isAppDataLoading, setIsAuthLoading] = useState(true);
  const [creditUnitPrice, setCreditUnitPrice] = useState(0);
  const [ngnUsdRate, setNgnUsdRate] = useState(0);
  const [isGenerateInputVisible, setIsGenerateInputVisible] = useState(true);
  const [shouldFocusGenerateInput, setShouldFocusGenerateInput] =
    useState(true);

  const { get } = useBackend();

  const fetchInitData = useCallback(
    (signal: AbortSignal | undefined = undefined) => {
      setIsAuthLoading(true);

      return get("/init", { signal })
        .then(async res => {
          if (res.ok) {
            try {
              const jsonResonponse = await res.json();

              const data = jsonResonponse.data;
              setCreditUnitPrice(data.creditUnitPrice);
              setNgnUsdRate(data.ngnUsdRate);
            } catch (error) {}
          }

          setIsAuthLoading(false);
        })
        .catch(e => {
          // If its AbortError, let's assume the user reloaded the page
          if (e.name !== "AbortError") {
            setIsAuthLoading(false);
          }
        });
    },
    [get]
  );

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    fetchInitData(signal);

    return () => {
      controller.abort();
    };
  }, [fetchInitData]);

  useEffect(() => {
    setTimeout(() => {
      const elem = document?.querySelector("#generateVideo");

      if (!elem) {
        setIsGenerateInputVisible(false);
      }
    }, 300);
  }, []);

  return (
    <AppContext.Provider
      value={{
        isAppDataLoading,
        fetchInitData,
        creditUnitPrice,
        ngnUsdRate,
        isGenerateInputVisible,
        setIsGenerateInputVisible,
        shouldFocusGenerateInput,
        setShouldFocusGenerateInput
      }}
    >
      <div className="app">{children}</div>
    </AppContext.Provider>
  );
};
