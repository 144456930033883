import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

import { Modal } from "components/General/Modal";
import SvgIcon from "../SvgIcon";

import Spinner from "../Spinner";
import useBackend from "hooks/useBackend";

import toast from "support/toast";
import { Channel } from "support/types";

import "./style.scss";
import { useConnectChannelPlatform } from "hooks/useConnectChannelPlatform";
import channelPlatforms from "support/channelPlatforms";

interface ConnectFacebookPagesModalProps {
  show?: boolean;
  onAdd?: (channel: Channel) => void;
  onClose?: () => void;
}

interface FacebookPage {
  id: string;
  name: string;
  image: string;
  connected: boolean;
}

export function ConnectFacebookPagesModal({
  show = false,
  onAdd,
  onClose
}: ConnectFacebookPagesModalProps) {
  const [pages, setPages] = useState<FacebookPage[]>([]);
  const [isPagesLoading, setIsPagesLoading] = useState(false);
  const [isAdding, setIsAdding] = useState<string | null>(null);
  const location = useLocation();
  const [searchParams, setURLSearchParams] = useSearchParams();

  const queryId = searchParams.get("id");
  const connectId = searchParams.get("connect");

  const { connecting, handleConnectClick } = useConnectChannelPlatform(
    encodeURIComponent(`${location.pathname}?share=1&platform=facebook`)
  );

  const { get, post } = useBackend();

  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }

    searchParams.delete("id");
    searchParams.delete("connect");
    setURLSearchParams(searchParams, { replace: true });
  };

  useEffect(() => {
    if (!queryId || connectId !== "facebook") {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;

    setIsPagesLoading(true);

    get(`/connect/facebook/pages?id=${queryId}`, { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setPages(jsonResonponse.data.pages);
          } catch (error) {}
        }
        setIsPagesLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsPagesLoading(false);
        }
      });

    return () => {
      controller.abort();
    };
  }, [get, queryId, connectId]);

  const handleAddPage = async (page: FacebookPage) => {
    setIsAdding(page.id);

    try {
      const res = await post("/connect/facebook/pages/add", {
        body: { id: queryId, pageId: page.id }
      });

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsAdding(null);
      } else {
        const channel = jsonResonponse.data.channel;

        const index = pages.findIndex(p => p.id === page.id);
        if (index !== -1) {
          setPages([
            ...pages.slice(0, index),
            { ...page, connected: true },
            ...pages.slice(index + 1)
          ]);
        }

        if (typeof onAdd === "function") {
          onAdd(channel);
        }

        setIsAdding(null);
      }
    } catch (error) {
      setIsAdding(null);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const facebookPlatform = channelPlatforms.find(p => p.id === "facebook");

  if (!facebookPlatform) {
    return null;
  }

  return (
    <>
      <Modal
        show={show}
        className="connect-fbpage-modal"
        onClose={handleOnClose}
      >
        {!isPagesLoading && pages.length > 0 && (
          <>
            <h2>Select Facebook Pages</h2>
            <p>
              If a Page is missing, you may not have selected it during
              authorization. For Business account Pages, ensure you select the
              Business account when prompted.{" "}
              <button
                className="btn btn-link p-0"
                disabled={!!connecting}
                onClick={() => handleConnectClick(facebookPlatform)}
              >
                {!!connecting ? (
                  <Spinner size="sm" color="primary" />
                ) : (
                  "Re-authorize"
                )}
              </button>
              .
            </p>
          </>
        )}

        <button type="button" className="btn-close" onClick={handleOnClose}>
          <SvgIcon name="close-circle" />
        </button>

        {isPagesLoading && (
          <div className="text-center p-5">
            {" "}
            <Spinner color="primary" size={5} />
          </div>
        )}

        {!isPagesLoading && pages.length > 0 && (
          <div className="fbpages">
            {pages.map(page => (
              <div key={page.id} className={clsx("fbpage-item")}>
                <img
                  src={page.image}
                  width={50}
                  height={50}
                  className="fbpage-image"
                  alt=""
                />
                <div className="fbpage-name">{page.name}</div>

                {page.connected ? (
                  <span className={clsx("badge connected-btn")}>Connected</span>
                ) : (
                  <button
                    className={clsx("btn connect-btn", {
                      adding: page.id === isAdding
                    })}
                    disabled={page.id === isAdding}
                    onClick={() => handleAddPage(page)}
                  >
                    {page.id === isAdding ? <Spinner /> : "Add"}
                  </button>
                )}
              </div>
            ))}
          </div>
        )}

        {!isPagesLoading && pages.length < 1 && (
          <div className="p-5">
            <h2>No Facebook Pages found</h2>
            <p>
              Ensure you selected the Facebook pages you want to add to Soutle
              during authorization. For Business account Pages, ensure you
              select the Business account when prompted.
            </p>
            <div className="text-center">
              <button
                className="btn btn-primary text-white py-1 px-4 rounded-pill"
                disabled={!!connecting}
                onClick={() => handleConnectClick(facebookPlatform)}
              >
                {!!connecting ? <Spinner size="sm" /> : "Re-authorize"}
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
}
