import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import Spinner from "components/General/Spinner";
import { ConnectChannelModal } from "components/General/ConnectChannelModal";
import { ConnectFacebookPagesModal } from "components/General/ConnectFacebookPagesModal";

import { Channel } from "support/types";
import useBackend from "hooks/useBackend";
import useTooltip from "hooks/useTooltip";
import { capitalizeFirstLetter } from "support/helpers";

import SvgIcon from "components/General/SvgIcon";
import alertController from "contexts/AlertContext/controller";
import useChannelList from "hooks/useChannelList";
import useConnectFbPages from "hooks/useConnectFbPages";

import styles from "./style.module.scss";

export default function Channels() {
  const [isShowConnectModal, setIsShowConnectChannelModal] = useState(false);

  const [isDeleting, setIsDeleting] = useState<string | null>(null);

  const { isShowFbPagesModal, setIsShowConnectFbPagesModal } =
    useConnectFbPages();

  const { del } = useBackend();

  const { channels, setChannels, isChannelsLoading } = useChannelList();

  useTooltip();

  const hideConnectChannelModal = () => {
    setIsShowConnectChannelModal(false);
  };

  const handleOnAdd = (channel: Channel) => {
    setChannels([...channels, channel]);
  };

  async function deleteChannel(channelId: string) {
    setIsDeleting(channelId);

    const response = await del(`/channels/${channelId}`);

    if (response.ok) {
      const index = channels.findIndex(ch => ch.id === channelId);

      if (index !== -1) {
        setChannels([
          ...channels.slice(0, index),
          ...channels.slice(index + 1)
        ]);
      }

      return setIsDeleting(null);
    }

    setIsDeleting(null);
  }

  const handleOnDeleteChannel = (channel: Channel) => {
    alertController.open({
      icon: "info",
      title: "Confirm delete Channel",
      message: (
        <>
          Are you sure you want to delete the Channel{" "}
          <strong>{channel.name}</strong>?
        </>
      ),
      buttonText: "Delete",
      buttonVariant: "danger",
      onOkay: () => {
        deleteChannel(channel.id);
      },
      cancelButtonText: "Cancel"
    });
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <h1>Channels</h1>

        {!isChannelsLoading && channels.length > 0 && (
          <button
            className="btn btn-primary px-4 text-white fw-bold rounded-pill"
            onClick={() => setIsShowConnectChannelModal(true)}
          >
            + Connect a Channel
          </button>
        )}
      </div>

      <div className={styles.channels}>
        {isChannelsLoading
          ? Array.from({ length: 10 }, (_, index) => (
              <div
                key={index + 1}
                className={clsx(styles.channelItem, styles.busy)}
              >
                <div className={styles.channelImageLoading}></div>
                <div className={styles.channelNameLoading}></div>
                <div className={styles.channelTypeLoading}></div>
              </div>
            ))
          : channels.map(channel => (
              <div key={channel.id} className={clsx(styles.channelItem)}>
                <div className={styles.channelImageWrap}>
                  <img
                    src={channel.photoUrl}
                    width={50}
                    height={50}
                    className={styles.channelImage}
                    alt=""
                  />
                  <div className={styles.platformIconWrap}>
                    <SvgIcon
                      name={`${channel.platform}-colored`}
                      className={styles.platformIcon}
                    />
                  </div>
                </div>
                <div className={styles.channelName}>{channel.name}</div>
                <div className={styles.channelType}>
                  {capitalizeFirstLetter(channel.platform)} {channel.type}
                </div>

                <div className={styles.actionBtns}>
                  {channel.platform === "facebook" &&
                    channel.type === "account" && (
                      <Link
                        data-bs-custom-class="white-tooltip"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Select Facebook pages"
                        to={`?connect=facebook&id=${channel.id}`}
                        className={clsx("btn", styles.actionButton)}
                      >
                        <SvgIcon name="menu" className={styles.btnIcon} />
                      </Link>
                    )}

                  <button
                    data-bs-custom-class="white-tooltip"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    data-bs-title="Delete channel"
                    className={clsx(
                      "btn",
                      styles.actionButton,
                      styles.deleteButton
                    )}
                    disabled={isDeleting === channel.id}
                    onClick={() => handleOnDeleteChannel(channel)}
                  >
                    {isDeleting ? (
                      <Spinner color="primary" />
                    ) : (
                      <SvgIcon name="delete" className={styles.btnIcon} />
                    )}
                  </button>
                </div>
              </div>
            ))}
      </div>

      {!isChannelsLoading && channels.length < 1 && (
        <div
          className={clsx(
            "rounded mt-4 d-flex align-items-center flex-column justify-content-center",
            styles.emptyBox
          )}
        >
          <p className="text-muted fw-normal w-50 mb-2">
            Connect your social media channels to seamlessly publish your
            generated videos directly to them.
          </p>
          <button
            className="btn btn-primary px-4 btn-sm text-white fw-bold rounded-pill"
            onClick={() => setIsShowConnectChannelModal(true)}
          >
            + Connect a Channel
          </button>
        </div>
      )}

      <ConnectChannelModal
        show={isShowConnectModal}
        onClose={hideConnectChannelModal}
      />

      <ConnectFacebookPagesModal
        show={isShowFbPagesModal}
        onAdd={handleOnAdd}
        onClose={() => setIsShowConnectFbPagesModal(false)}
      />
    </div>
  );
}
