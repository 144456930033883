import { Plan } from "./types";

export const getPlanPrice = (
  plan: Plan,
  frequency: string,
  currency: string = "USD"
) => {
  const annualPrice = plan.prices.find(
    p => p.interval === "annually" && p.currency === currency
  );
  const monthlyPrice = plan.prices.find(
    p => p.interval === "monthly" && p.currency === currency
  );

  return frequency === "monthly" ? monthlyPrice : annualPrice;
};

export const getPlanPriceRate = (
  plan: Plan,
  frequency: string,
  currency: string = "USD"
) => {
  const price = getPlanPrice(plan, frequency, currency);

  return price?.amountMonthly;
};

export const getPriceTotal = (rate: number, frequency: string) => {
  return frequency === "annually" ? rate * 12 : rate;
};

export const formatMoney = (amount: number, currency: string = "usd") => {
  const lang = currency.toLowerCase() === "ngn" ? "en-NG" : "en-US";

  const formatter = new Intl.NumberFormat(lang, {
    style: "currency",
    currency
  });

  return formatter.format(amount);
};

/**
 * Creates a debounced version of the provided function, which delays its
 * execution until after `delay` milliseconds have elapsed since the last
 * time it was invoked.
 *
 * @param fn - The function to debounce.
 * @param delay - The delay, in milliseconds, before the function is invoked.
 * @returns A debounced version of `fn`.
 */
export function debounce<F extends (...args: any[]) => void>(
  fn: F,
  delay: number
): (...args: Parameters<F>) => void {
  let timeoutId: number | undefined;

  return (...args: Parameters<F>): void => {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = window.setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
