import { useEffect } from "react";
import { Tooltip } from "bootstrap";

export default function useTooltip() {
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      tooltipTriggerEl =>
        new Tooltip(tooltipTriggerEl, {
          trigger: "hover"
        })
    );

    return () => {
      tooltipList.forEach(tip => {
        tip.dispose();
      });
    };
  });

  return null;
}
