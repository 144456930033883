import { useEffect, useRef, useState } from "react";

import WelcomeBox from "components/Dashboard/WelcomeBox";
import VideosGrid from "components/Dashboard/VideosGrid";
import GenereateInput from "components/Dashboard/GenerateInput";

import { useApp } from "contexts/AppContext";

import styles from "./style.module.scss";

export default function Dashboard() {
  const { isGenerateInputVisible, setIsGenerateInputVisible } = useApp();

  const targetRef = useRef(null);

  useEffect(() => {
    // Create an IntersectionObserver that sets "isPast" to true
    // whenever the target element is fully out of the viewport.
    const observer = new IntersectionObserver(
      ([entry]) => {
        // If entry.isIntersecting is false, it means the element
        // is not in the viewport (scrolled past).
        setIsGenerateInputVisible(entry.isIntersecting);
      },
      {
        root: null, // Use the browser viewport as the container.
        rootMargin: "-60px", // No margin around the container.
        threshold: 0 // Trigger when the element is fully out of view.
      }
    );

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup the observer on unmount.
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return (
    <>
      <div ref={targetRef}>
        {isGenerateInputVisible && (
          <GenereateInput className={styles.generateInput} />
        )}
      </div>

      {/* <WelcomeBox /> */}

      <VideosGrid title="Recent Videos" className={styles.recentItems} />
    </>
  );
}
