import { z } from "zod";
import clsx from "clsx";
import { FormEvent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ValidationErrors } from "support/types";
import toast from "support/toast";

import GenerateInputDropdown from "./DropdownMenu";

import useBackend from "hooks/useBackend";
import { useApp } from "contexts/AppContext";

import styles from "./style.module.scss";

type GenerateEbookInputProps = {
  className?: string;
};

export default function GenerateEbookInput(props: GenerateEbookInputProps) {
  const navigate = useNavigate();
  const [isFocused, setIsFocused] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPristine, setIsPristine] = useState<boolean>(true);
  const [errors, setErrors] = useState<ValidationErrors>({});

  // A ref to the input element
  const inputRef = useRef<HTMLInputElement>(null);

  const { shouldFocusGenerateInput, setShouldFocusGenerateInput } = useApp();

  const { post } = useBackend();

  const formRef = useRef<HTMLFormElement>(null);

  const validateForm = (e: FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);

    const schema = z.object({
      title: z.string()
    });

    return schema.safeParse(Object.fromEntries(data));
  };

  const handleFormChange = async (e: FormEvent<HTMLFormElement>) => {
    if (isPristine) {
      return;
    }

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setErrors({});
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsPristine(false);

    const zResult = validateForm(e);

    if (!zResult.success) {
      setErrors(zResult.error.flatten().fieldErrors);
    } else {
      setIsLoading(true);

      try {
        const res = await post("/videos/magicgen", {
          body: zResult.data
        });

        const jsonResonponse = await res.json();

        if (!res.ok) {
          if (jsonResonponse.type === "validation") {
            setErrors(jsonResonponse.errors);
          } else if (jsonResonponse.message) {
            toast.error(jsonResonponse.message);
          } else {
            // This will activate the closest `error.js` Error Boundary
            throw new Error("Failed to process data");
          }

          setIsLoading(false);
        } else if (jsonResonponse.status === 200) {
          const video = jsonResonponse.data.video;

          navigate(`/editor/${video.id}`);

          toast.success(
            "Your video has been successfully added to the render queue."
          );
          formRef.current?.reset();

          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (errors) {
      for (const key in errors) {
        if (Object.prototype.hasOwnProperty.call(errors, key)) {
          const errs = errors[key];

          toast.error(errs[0]);
        }
      }
    }
  }, [errors]);

  // Watch shouldFocusGenerateInput. If true, focus the input and reset flag.
  useEffect(() => {
    if (shouldFocusGenerateInput && inputRef.current) {
      inputRef.current.focus();

      setShouldFocusGenerateInput(false);
    }
  }, [shouldFocusGenerateInput, setShouldFocusGenerateInput]);

  return (
    <>
      <form
        ref={formRef}
        className={clsx(styles.generateInputWrap, props.className, {
          [styles.focused]: isFocused
        })}
        onSubmit={e => handleSubmit(e)}
        onChange={e => handleFormChange(e)}
      >
        {/* <label htmlFor="generateVideo" className={styles.generateInputLabel}>
        Video title:
      </label> */}
        <input
          ref={inputRef}
          id="generateVideo"
          type="text"
          name="title"
          placeholder="Enter Video Title"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          aria-label="Video title"
        />

        <GenerateInputDropdown loading={isLoading} />
      </form>
    </>
  );
}
