import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { store } from "./store";

import reportWebVitals from "./reportWebVitals";

// Global systes must come before App import
// This is so that components can overwrite global styles
import "./index.scss";

import App from "./App";

import { AppProvider } from "contexts/AppContext";
import { AuthProvider } from "contexts/AuthContext";
import { BillingProvider } from "contexts/BillingContext";
import { AlertProvider } from "contexts/AlertContext";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Load your Stripe publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Elements stripe={stripePromise}>
          <AppProvider>
            <AuthProvider>
              <BillingProvider>
                <AlertProvider>
                  <App />
                </AlertProvider>
              </BillingProvider>
            </AuthProvider>

            <ToastContainer />
          </AppProvider>
        </Elements>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
