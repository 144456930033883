import clsx from "clsx";
import { Dropdown } from "bootstrap";
import { useEffect, useRef, useState } from "react";

import SvgIcon from "../SvgIcon";

import Scrollbar from "../Scrollbar";
import { Video } from "support/types/videos";
import ShareToFacebook from "./components/ShareToFacebook";
import ShareToInstagram from "./components/ShareToInstagram";
import ShareToYoutube from "./components/ShareToYoutube";
import ShareToTiktok from "./components/ShareToTiktok";
import { useVideoEditor } from "contexts/VideoEditorContext";

import "./style.scss";
import { useSearchParams } from "react-router-dom";

interface ShareVideoDropdownProps {
  video: Video;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
  onChange?: (voiceId: string) => void;
}

const ShareVideoDropdown = ({
  video,
  disabled,
  children,
  className
}: ShareVideoDropdownProps) => {
  const dropdownToggleElemRef = useRef<HTMLButtonElement | null>(null);
  const dropdownInstance = useRef<Dropdown | null>(null);
  const [postTo, setPostTo] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const queryShare = searchParams.get("share");
  const queryPlatform = searchParams.get("platform");

  const { isVideoLoading } = useVideoEditor();

  useEffect(() => {
    if (!dropdownToggleElemRef.current) {
      return;
    }

    dropdownInstance.current = new Dropdown(dropdownToggleElemRef.current, {
      offset: [0, 15],
      autoClose: false
    });

    const handleOnDropdownHidden = () => {
      setPostTo(null);
    };

    const dropdownButton = dropdownToggleElemRef.current;
    if (dropdownButton) {
      dropdownButton.addEventListener(
        "hidden.bs.dropdown",
        handleOnDropdownHidden
      );
    }

    return () => {
      dropdownButton?.removeEventListener(
        "hidden.bs.dropdown",
        handleOnDropdownHidden
      );

      if (dropdownInstance.current) {
        dropdownInstance.current.dispose();
      }
    };
  }, []);

  useEffect(() => {
    if (!!queryShare && !isVideoLoading) {
      setTimeout(() => {
        setPostTo(queryPlatform);

        dropdownInstance.current?.show();
      }, 1000);
    }
  }, [queryShare, queryPlatform, isVideoLoading]);

  const renderSelectedPlatformComponent = () => {
    if (postTo === "facebook") {
      return (
        <ShareToFacebook video={video} onBackClick={() => setPostTo(null)} />
      );
    } else if (postTo === "instagram") {
      return (
        <ShareToInstagram video={video} onBackClick={() => setPostTo(null)} />
      );
    } else if (postTo === "youtube") {
      return (
        <ShareToYoutube video={video} onBackClick={() => setPostTo(null)} />
      );
    } else if (postTo === "tiktok") {
      return (
        <ShareToTiktok video={video} onBackClick={() => setPostTo(null)} />
      );
    }

    return null;
  };

  return (
    <div className="dropdown share-video-dropdown">
      <button
        ref={dropdownToggleElemRef}
        className={clsx("dropdown-toggle", className)}
        type="button"
        data-bs-toggle="dropdown"
        disabled={disabled}
      >
        {children}
      </button>
      <div className="dropdown-menu">
        {!postTo ? (
          <>
            <div className="share-dropdown-header">
              Share this video
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => dropdownInstance.current?.hide()}
              ></button>
            </div>

            <Scrollbar className="share-dropdown-scroll">
              <div className="share-dropdown-content">
                <a
                  href={video?.videoUrl}
                  className="btn btn-primary text-white w-100"
                  target="_blank"
                  rel="noreferrer"
                  download
                >
                  Download
                </a>

                <div className="socials-wrap">
                  <div className="socials-heading">Post to Channels</div>
                  <div className="socials-items">
                    <div className="social-item">
                      <button
                        className="btn btn-social"
                        disabled={disabled}
                        onClick={() => setPostTo("facebook")}
                      >
                        <SvgIcon name="facebook-colored" />
                      </button>
                      <div className="social-name">Facebook</div>
                    </div>
                    <div className="social-item">
                      <button
                        className="btn btn-social"
                        disabled={disabled}
                        onClick={() => setPostTo("instagram")}
                      >
                        <SvgIcon name="instagram-colored" />
                      </button>
                      <div className="social-name">Instagram</div>
                    </div>
                    <div className="social-item">
                      <button
                        className="btn btn-social"
                        disabled={disabled}
                        onClick={() => setPostTo("youtube")}
                      >
                        <SvgIcon name="youtube-colored" />
                      </button>
                      <div className="social-name">Youtube</div>
                    </div>
                    <div className="social-item">
                      <button
                        className="btn btn-social"
                        disabled={disabled}
                        onClick={() => setPostTo("tiktok")}
                      >
                        <SvgIcon name="tiktok-colored" />
                      </button>
                      <div className="social-name">Tiktok</div>
                    </div>
                  </div>
                </div>
              </div>
            </Scrollbar>
          </>
        ) : (
          renderSelectedPlatformComponent()
        )}
      </div>
    </div>
  );
};

export default ShareVideoDropdown;
