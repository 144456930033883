import VideoPlayer from "components/General/VideoPlayer";
import { Video } from "support/types/videos";

import styles from "./style.module.scss";
import clsx from "clsx";
import { MouseEvent } from "react";

interface VideoPlayerModalProps {
  video: Video;
  onClose?: () => void;
}

export const VideoPlayerModal = ({ video, onClose }: VideoPlayerModalProps) => {
  const handleOnClose = () => {
    if (typeof onClose === "function") {
      onClose();
    }
  };

  const handleOnContentClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  if (!video.videoUrl) {
    return null;
  }

  return (
    <div className={styles.VideoPlayerModal} onClick={handleOnClose}>
      <div className={styles.header}>
        <button
          className={clsx("btn btn-light", styles.closeBtn)}
          onClick={handleOnClose}
        >
          Close
        </button>
      </div>

      <div
        className={styles.VideoPlayerModalContent}
        onClick={handleOnContentClick}
      >
        <VideoPlayer
          src={video.videoUrl}
          poster={video.posterThumbUrl}
          autoPlay
        />
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};
