import {
  createContext,
  useContext,
  ReactNode,
  FC,
  useEffect,
  useState,
  useCallback
} from "react";

import { UpgradeModal } from "components/Billing/UpgradeModal";
import { TopUpModal } from "components/Billing/TopUpModal";

import {
  AuthUser,
  Feature,
  PaymentMethod,
  Plan,
  Subscription
} from "support/types";
import SocketClient from "support/SocketClient";
import { useAuth } from "./AuthContext";
import { useAppDispatch } from "hooks";
import { setUser } from "store/reducers/authSlice";
import toast from "support/toast";
import useBackend from "hooks/useBackend";

interface BillingContextProps {
  currencySymbol: string;
  plans: Plan[];
  userPlan: Plan | undefined;
  isPlansLoading: boolean;
  isPlansLoaded: boolean;
  isUserOnHighestPlan: boolean;
  loadPlans: () => void;

  isResuming: boolean;
  resumeSubscription: () => void;

  features: Feature[];
  userFeatures: Feature[];

  paymentMethods: PaymentMethod[];
  paymentMethodsLoading: boolean;
  hasMorePaymentMethods: boolean;
  loadPaymentMethods: (startAfter?: string) => void;
  setPaymentMethods: (pm: PaymentMethod[]) => void;

  showUpgradeModal: () => void;
  hideUpgradeModal: () => void;

  showTopUpModal: () => void;
  hideTopUpModal: () => void;
}

const BillingContext = createContext<BillingContextProps | undefined>(
  undefined
);

export const useBilling = (): BillingContextProps => {
  const context = useContext(BillingContext);
  if (!context) {
    throw new Error("useModal must be used within a BillingProvider");
  }
  return context;
};

interface BillingProviderProps {
  children: ReactNode;
}

export const BillingProvider: FC<BillingProviderProps> = ({ children }) => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [features, setFeatures] = useState<Feature[]>([]);
  const [isPlansLoaded, setIsPlansLoaded] = useState(false);
  const [isPlansLoading, setIsPlansLoading] = useState(false);
  const [isShowUpgradeModal, setIsShowUpgradeModal] = useState(false);
  const [isShowTopUpModal, setIsShowTopUpModal] = useState(false);
  const [isResuming, setIsResuming] = useState<boolean>(false);

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(false);
  const [hasMorePaymentMethods, setHasMorePaymentMethods] = useState(false);

  const dispatch = useAppDispatch();

  const { get, post } = useBackend();

  const { user } = useAuth();

  const currencySymbol = user?.currency === "NGN" ? "₦" : "$";

  const loadPlans = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    setIsPlansLoading(true);

    get("/billing/plans", { signal })
      .then(async res => {
        if (res.ok) {
          try {
            const jsonResonponse = await res.json();

            setPlans(jsonResonponse.data.plans);
            setFeatures(jsonResonponse.data.features);
            setIsPlansLoaded(true);
          } catch (error) {}
        }
        setIsPlansLoading(false);
      })
      .catch(e => {
        if (e.name !== "AbortError") {
          setIsPlansLoading(false);
        }
      });
  }, [get]);

  const loadPaymentMethods = useCallback(
    async (startAfter?: string) => {
      setPaymentMethodsLoading(true);

      try {
        let url = `/billing/payment-methods`;

        let params: { currency?: string; cur?: string } = {
          currency: user?.currency
        };

        if (startAfter) {
          params = { ...params, cur: startAfter };
        }

        const hasParams = Object.keys(params).length > 0;

        const queryString = new URLSearchParams(params).toString();

        url = hasParams ? `${url}?${queryString}` : url;

        const res = await get(url);

        const jsonResonponse = await res.json();

        if (!res.ok) {
          if (jsonResonponse.type === "validation") {
            toast.error("An error occurred while processing your request");
          } else {
            toast.error("An error occurred while processing your request");
          }

          setPaymentMethodsLoading(false);
        } else {
          setHasMorePaymentMethods(jsonResonponse.data.hasMore);

          if (startAfter) {
            setPaymentMethods([
              ...paymentMethods,
              ...jsonResonponse.data.paymentMethods
            ]);
          } else {
            setPaymentMethods(jsonResonponse.data.paymentMethods);
          }

          setPaymentMethodsLoading(false);
        }
      } catch (error) {
        setPaymentMethodsLoading(false);
        // Capture the error message to display to the user
        console.error(error);
      }
    },
    [get, paymentMethods, user?.currency]
  );

  const resumeSubscription = async () => {
    setIsResuming(true);

    try {
      const res = await post("/billing/subscription/resume");

      const jsonResonponse = await res.json();

      if (!res.ok) {
        if (jsonResonponse.type === "validation") {
          toast.error("An error occurred while processing your request");
        } else {
          toast.error("An error occurred while processing your request");
        }

        setIsResuming(false);
      } else if (jsonResonponse.status === 200) {
        // Note: This endpoint when successful emits an subscriptionUpdated event
        // which is then handled by the BillingContext Provider.
        dispatch(
          setUser({
            ...user,
            subscription: {
              ...user?.subscription,
              status: "ACTIVE"
            }
          } as AuthUser)
        );

        toast.success("Success! Your subscription will continue as usual.");

        setIsResuming(false);
      }
    } catch (error) {
      setIsResuming(false);
      // Capture the error message to display to the user
      console.error(error);
    }
  };

  const showUpgradeModal = () => {
    if (!isPlansLoaded) {
      loadPlans();
    }

    setIsShowUpgradeModal(true);
  };

  const hideUpgradeModal = () => {
    setIsShowUpgradeModal(false);
  };

  const showTopUpModal = () => {
    if (!isPlansLoaded) {
      loadPlans();
    }

    setIsShowTopUpModal(true);
  };

  const hideTopUpModal = () => {
    setIsShowTopUpModal(false);
  };

  const handleCreditsUpdated = useCallback(
    ({ credits }: { credits: number }) => {
      dispatch(
        setUser({
          ...user,
          credits: credits
        } as AuthUser)
      );
    },
    [user, dispatch]
  );

  const handleSubscriptionUpdated = useCallback(
    ({ subscription }: { subscription: Subscription }) => {
      dispatch(
        setUser({
          ...user,
          subscription
        } as AuthUser)
      );
    },
    [user, dispatch]
  );

  const handleCardAdded = useCallback(
    ({
      paymentMethod,
      hasPaystackAuth = false
    }: {
      paymentMethod: PaymentMethod;
      hasPaystackAuth: boolean;
    }) => {
      dispatch(
        setUser({
          ...user,
          hasPaystackAuth
        } as AuthUser)
      );

      setPaymentMethods([paymentMethod, ...paymentMethods]);
    },

    [user, dispatch, paymentMethods]
  );

  useEffect(() => {
    const socketClient = SocketClient.getInstance();
    const socket = SocketClient.getSocket();
    const userBillingChannel = `${user?.id}-billing`;

    if (user) {
      socketClient.subscribeToChannel(userBillingChannel);

      socket.on("creditsUpdated", handleCreditsUpdated);
      socket.on("subscriptionUpdated", handleSubscriptionUpdated);
      socket.on("paymentMethodAdded", handleCardAdded);
    }

    return () => {
      if (user) {
        socket.off("creditsUpdated", handleCreditsUpdated);
        socket.off("subscriptionUpdated", handleSubscriptionUpdated);
        socket.off("paymentMethodAdded", handleCardAdded);
        socketClient.unsubscribeFromChannel(userBillingChannel);
      }
    };
  }, [user, handleCreditsUpdated, handleSubscriptionUpdated, handleCardAdded]);

  const freePlan = plans.find(p => p.slug === "free");

  let userPlan: Plan | undefined = freePlan;
  if (user?.subscription) {
    const plan = plans.find(p => p.slug === user.subscription.planSlug);

    if (plan) {
      userPlan = plan;
    }
  }

  const userFeatures = features.map(ft => {
    const userPlanFts = userPlan?.features;

    const foundUserPlanFt = userPlanFts?.find(uft => uft.id === ft.id);

    if (foundUserPlanFt) {
      return {
        ...ft,
        value: foundUserPlanFt.value,
        enabled: true
      };
    }

    return {
      ...ft,
      enabled: false
    };
  });

  const isUserOnHighestPlan =
    !!user?.subscription &&
    userPlan?.slug === "premiumplus" &&
    user.subscription.priceSlug === "premiumplus_annually";

  return (
    <BillingContext.Provider
      value={{
        currencySymbol,
        plans,
        userPlan,
        isPlansLoading,
        isPlansLoaded,
        isUserOnHighestPlan,
        loadPlans,

        isResuming,
        resumeSubscription,

        features,
        userFeatures,

        paymentMethods,
        paymentMethodsLoading,
        hasMorePaymentMethods,
        loadPaymentMethods,
        setPaymentMethods,

        showUpgradeModal,
        hideUpgradeModal,

        showTopUpModal,
        hideTopUpModal
      }}
    >
      {children}

      <TopUpModal show={isShowTopUpModal} onClose={hideTopUpModal} />
      <UpgradeModal show={isShowUpgradeModal} onClose={hideUpgradeModal} />
    </BillingContext.Provider>
  );
};
